const InputDate = ({ ...props }) => {
  return (
    <div className="relative w-[170px] h-[40px]">
      <input
        type="date"
        name=""
        id=""
        className="w-full outline-none border border-input rounded-md pe-2 h-full absolute top-0 left-0 z-[2]"
        {...props}
      />
    </div>
  );
};

export default InputDate;
