import React from "react";
import { MdOutlineDone } from "react-icons/md";
import { IoMdDoneAll } from "react-icons/io";
import { IoPersonCircle } from "react-icons/io5";

const Message = ({ e, img }) => {
    return (
        <div className="w-full">
            <div className="flex items-start gap-1">
                {img ? <img src={img} alt="" /> : <IoPersonCircle size={40} />}
                <div className="bg-main text-white rounded-xl min-w-[200px] max-w-[50%] p-2 text-base">
                    {e?.data}
                    {e?.text}
                    <div className="mt-2 text-xs flex w-full justify-between items-center ">
                        <p className="text-base">
                            {e?.isRead ? (
                                <IoMdDoneAll
                                    className="text-white"
                                    id="isRead"
                                />
                            ) : !e?.isRecived ? (
                                <MdOutlineDone className="text-slate-100/60" />
                            ) : (
                                <IoMdDoneAll
                                    className="text-slate-100/60"
                                    id="isNotRead"
                                />
                            )}
                        </p>
                        <p>{e?.time}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Message;
