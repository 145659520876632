import React, { useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
import { GrAttachment } from "react-icons/gr";
import { useParams } from "react-router";
import { useGetConvDetails } from "../../hook/users";
import { CircularProgress } from "@mui/material";
import { baseURL } from "../../hook/request";
import { IoPersonCircle } from "react-icons/io5";
import Replay from "../chat/replay";
import Message from "../chat/message";
import useWebsiteStore from "../../store/store";

const ChatDet = () => {
    const { convid } = useParams();
    const { data: detail, isLoading } = useGetConvDetails(convid, {
        IsPaginat: true,
        PageNumber: 1,
        PageSize: 10,
    });
    useEffect(() => {
        const element = document.getElementById("chatinterface");
        if (element) {
            element.scrollTop = element.scrollHeight - element.clientHeight;
        }
    }, [detail]);

    const setOpen = useWebsiteStore((state) => state.setOpenChat);
    return isLoading ? (
        <div className="w-full flex justify-center items-center h-[50vh]">
            {" "}
            <CircularProgress />
        </div>
    ) : (
        <div className="flex flex-col  w-full border-e overflow-auto border-slate-200 pb-2 max-h-[100vh] ">
            <div className="flex items-center w-full sm:justify-end justify-between px-6 py-4 border-b border-slate-200 ">
                <p
                    onClick={() => setOpen(true)}
                    className="cursor-pointer text-base font-bold   sm:hidden  rounded-full text-main"
                >
                    <FaChevronRight />
                </p>
                <div className=" flex justify-end  gap-2 items-center ">
                    <div className="flex flex-col  justify-end">
                        <p className="text-sm "> {detail?.data?.name}</p>
                        <div className="flex items-center justify-end gap-1">
                            {detail?.data?.isActive && (
                                <>
                                    <p className="text-[10px] text-[#707991]">
                                        online
                                    </p>
                                    <span className="w-2 h-2 bg-[#68D391] rounded-full" />
                                </>
                            )}
                        </div>
                    </div>
                    {detail?.data?.profileImageUrl ? (
                        <img
                            src={`${baseURL}${detail?.data?.profileImageUrl}`}
                            alt="صورة المستخدم"
                            className="w-[33px] rounded-full"
                        />
                    ) : (
                        <IoPersonCircle size={34} />
                    )}
                </div>
            </div>
            <div
                id="chatinterface"
                // ref={outerDiv}
                className="h-full overflow-auto relative pt-4  px-6 min-h-[80vh]"
            >
                <div
                    // ref={innerDiv}
                    className="relative  w-full flex flex-col gap-2"
                >
                    {detail?.data.messages
                        .slice()
                        .reverse()
                        .map((e) =>
                            !e?.isRecived ? (
                                <Message e={e} key={e.id} />
                            ) : (
                                <Replay e={e} key={e.id} />
                            )
                        )}
                </div>
            </div>
            <div className="w-full relative py-4 px-4 flex items-center gap-4">
                <img
                    src="/assets/images/sent.png"
                    className="absolute   right-7 top-1/2 -translate-y-1/2"
                    alt=""
                />
                <input
                    type="text"
                    name=""
                    disabled
                    className="border outline-none text-black/70 focus:placeholder:opacity-0 placeholder:transition-all w-full ltr border-stone-200 px-4 pr-10 rounded-md py-2"
                    placeholder="اكتب رسالة"
                    id=""
                />
                <GrAttachment className="text-xl cursor-pointer" />
            </div>
        </div>
    );
};

export default ChatDet;
