import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { RiAddLargeFill } from "react-icons/ri";
import ChannelContact from "./channel-contact";
import { IoIosArrowBack } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";
import { useGetAllChannel } from "../../hook/channel";
import { CircularProgress } from "@mui/material";

const ChannelMenu = () => {
    const isWide = useMediaQuery("(min-width: 991px)");
    const location = useLocation();
    const [open, setopen] = useState(true);
    const { data, isLoading } = useGetAllChannel({
        IsPaginat: true,
        PageNumber: 1,
        PageSize: 10,
    });
    useEffect(() => {
        if (!isWide) {
            setopen(false);
        } else {
            setopen(true);
        }
    }, [isWide]);
    useEffect(() => {
        if (!isWide) {
            setopen(false);
        }
    }, [location.pathname]);
    return isLoading ? (
        <div className="w-fit flex justify-center items-center h-[50vh]">
            {" "}
            <CircularProgress />
        </div>
    ) : (
        <div div className=" relative z-10 h-[calc(100vh - 56px)] bg-white ">
            {" "}
            {!isWide && (
                <span
                    onClick={() => setopen(!open)}
                    className=" cursor-pointer absolute    top-7     z-40"
                    style={{
                        rotate: open ? "0deg" : "180deg",
                        left: open ? "0px" : "0px",
                    }}
                >
                    <IoIosArrowBack size={25} />
                </span>
            )}
            <div
                className=" z-10 bg-white w-0 overflow-hidden transition-all h-[calc(100vh-56px)] flex flex-col  absolute left-0 sm:relative "
                style={{
                    width: open ? "315px" : "0",
                }}
            >
                <div className=" flex flex-row justify-between   p-6 ">
                    <div className=" w-[33px] h-[33px] bg-main rounded-full flex justify-center items-center cursor-pointer  ">
                        <RiAddLargeFill fill="#fff" />
                    </div>
                    <h1 className=" font-semibold text-[20px]">القنوات</h1>
                </div>
                <div className=" flex flex-col  justify-center items-center ">
                    {data &&
                        data?.data?.map((item) => (
                            <NavLink
                                to={`/channel/chat/${item.id}`}
                                className={({ isActive }) =>
                                    isActive
                                        ? "border-l-[25px] border-l-main   w-full"
                                        : "felx flex-row cursor-pointer  gap-7   w-full hover:border-l-[25px] hover:border-l-main transition-all"
                                }
                            >
                                <ChannelContact
                                    key={item.id}
                                    name={item.name}
                                    imageUrl={item.imageUrl}
                                />
                            </NavLink>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default ChannelMenu;
