import { create } from "zustand";
import { persist } from "zustand/middleware";
import { getFromSessionStorage, key } from "../utils/helpers";

const initState = {
  otp: null,
  token: getFromSessionStorage(key),
  openChat: true,
};

const websiteStoreInit = (set) => ({
  ...initState,
  setOtp: (otp) => set({ otp }),
  setToken: (token) => set({ token }),
  setOpenChat: (openChat) => set({ openChat }),
});

const useWebsiteStore = create(
  persist(websiteStoreInit, {
    name: "website-store",
    partialize: (state) => ({
      hideTemplateInfoPopup: state.hideTemplateInfoPopup,
      icdAccessToken: state.icdAccessToken,
    }),
  })
);

export default useWebsiteStore;
