import React from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { baseURL } from "../../hook/request";
import { motion } from "framer-motion";
const SingleMessage = ({
    text,
    imageUrls,
    loadingg,
    display,
    handleSend,
    sent,
}) => {
    const message = text;
    const img = imageUrls;

    return (
        <div className=" flex flex-col  gap-5  p-5">
            <p className=" text-paragraph-light font-[12px]  w-fit m-auto min-h-[24px] ">
                {loadingg && "جاري الارسال"}
                {!loadingg && display && !sent && (
                    <p className=" text-error" onClick={handleSend}>
                        {" "}
                        <span className=" whitespace-nowrap cursor-pointer">
                            {" "}
                            لم يتم الارسال اضغط للارسال مجددا
                        </span>
                    </p>
                )}
            </p>
            <motion.div className=" flex md:flex-row  flex-col  justify-between items-start md:gap-1 gap-2 ">
                <motion.div
                    initial={{ x: 30, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className=" flex flex-row gap-3  justify-start items-start md:max-w-[800px]   "
                >
                    <img
                        src="/assets/images/reaction.jpg"
                        alt=""
                        className="   rounded cursor-pointer"
                    />
                    <div className=" flex md:flex-row flex-col justify-between gap-3 md:max-w-[800px]  w-full   bg-white  p-[10px] rounded-xl shadow-md">
                        {imageUrls?.[0] && (
                            <div className=" rounded-md overflow-hidden   xsm:w-[260px] md:h-[150px] object-cover  w-[180px] h-[150px]">
                                <img
                                    src={`${baseURL}${img}`}
                                    alt=""
                                    className=" object-cover w-full h-[150px]"
                                />
                            </div>
                        )}
                        <p className=" flex-1 p-2">{message} </p>
                    </div>
                </motion.div>
                <div className=" w-fit cursor-pointer">
                    <RiDeleteBinLine fill="#5E5873" size={24} />
                </div>
            </motion.div>
        </div>
    );
};

export default SingleMessage;
