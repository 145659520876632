import { Button, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import BaseInput from "../base-input";
import InputImage from "../input-image";
import { toast } from "react-toastify";
import { useAddCategory } from "../../hook/settings";
import { AnimatePresence, motion } from "framer-motion";

const AddCategory = ({ open, setOpen,refetchdata }) => {
    const [ImageUrl, setImageUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        name: "",
        onlyVideo: true,
        DislayForUser:true
    });

    const AddCategory = useAddCategory({
        onSuccess: () => {
            toast.success("Category added successfully!");
            setImageUrl(null);
            refetchdata()
            setData({ name: "", onlyVideo: true,DislayForUser:false });
        },
        onError: (error) => {
            toast.error("Error adding Category");
        },
        payload: data,
    });
    const handleSubmit = async () => {
        // setData({ ...data, urlImage: ImageUrl });
        setLoading(true);
        try {
            await AddCategory.mutateAsync();
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        } catch (error) {
            setLoading(false);
        }
        setLoading(false);
    };
    useEffect(() => {
        setData({ ...data, urlImage: ImageUrl });
    }, [ImageUrl]);
    return (
        <AnimatePresence>
            {open && (
                <motion.div className="fixed inset-0 flex items-center justify-center z-20 bg-[#00000033] bg-opacity-50">
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="bg-white shadow-lg z-40 relative rounded-[5px]  sm:w-[711px] w-[95%]"
                    >
                        <div className="flex flex-row justify-between items-center bg-[#D3EDB4] rounded-[5px] py-[15px] pr-[35px] pl-[18px] ">
                            <p className="font-light text-[16px] text-black">
                                اضافة تصنيف
                            </p>
                            <img
                                src="/assets/images/close.svg"
                                alt="close"
                                onClick={() => {
                                    setOpen(false);
                                    setImageUrl(null);
                                    setData({ name: "", onlyVideo: true,DislayForUser:false });
                                }}
                                className="cursor-pointer"
                            />
                        </div>
                        <Container>
                            <div className=" flex sm:flex-col flex-col  justify-between p-4  gap-[3rem] py-[3rem]  ">
                                <div className="flex sm:flex-row flex-col  items-center ">
                                     <BaseInput
                                            label={"اسم التصنيف"}
                                            type="text"
                                            value={data.name}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    name: e.target.value,
                                                })
                                            }
                                        /> 
                                   <div className=" flex flex-row gap-3 justify-between"> <div className=" w-full">
                                        <FormControlLabel
                                            dir="rtl"
                                            control={
                                                <Checkbox
                                                    checked={data.onlyVideo}
                                                    onChange={(e) =>
                                                        setData({
                                                            ...data,
                                                            onlyVideo:
                                                                e.target
                                                                    .checked,
                                                        })
                                                    }
                                                />
                                            }
                                            label="فقط فيديوهات"
                                            sx={{
                                                "& .MuiFormControlLabel-label":
                                                    {
                                                        fontSize: "13px",
                                                        fontWeight: "normal",
                                                        color: "#4B465C",
                                                        width: "auto",
                                                        whiteSpace:'nowrap'
                                                    },
                                            }}
                                        />
                                    </div>
                                    <div className=" w-full">
                                        <FormControlLabel
                                            dir="rtl"
                                            control={
                                                <Checkbox
                                                    checked={data.DislayForUser}
                                                    onChange={(e) =>
                                                        setData({
                                                            ...data,
                                                            DislayForUser:
                                                                e.target
                                                                    .checked,
                                                        })
                                                    }
                                                />
                                            }
                                            label=" اظهار للمستخدمين"
                                            sx={{
                                                "& .MuiFormControlLabel-label":
                                                    {
                                                        fontSize: "13px",
                                                        fontWeight: "normal",
                                                        color: "#4B465C",
                                                        width: "auto",
                                                     whiteSpace:'nowrap'
                                                    },
                                            }}
                                        />
                                    </div></div>
                                </div>
                                <div className=" w-[329px]  h-[247px]   flex flex-col gap-2 ">
                                    <InputImage
                                        ImageUrl={ImageUrl}
                                        setImageUrl={setImageUrl}
                                        type="CategoryImage"
                                    />
                                </div>
                            </div>
                            <div className=" flex flex-row  justify-end gap-[10px] p-[2rem] ">
                                {" "}
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    إضافة
                                </Button>
                                <Button
                                    onClick={() => {
                                        setOpen(false);
                                        setImageUrl(null);
                                        setData({ name: "", onlyVideo: true });
                                    }}
                                    variant="outlined"
                                >
                                    تراجع
                                </Button>
                            </div>
                        </Container>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default AddCategory;
