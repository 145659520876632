import { Button, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputImage from "../input-image";
import { useAddChannel } from "../../hook/channel";
import BaseInput from "../base-input";
import BaseTextarea from "../base-textarea";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
const NewChannel = ({ open, setOpen, refetch }) => {
    const [ImageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        name: "",
        description: "",
    });

    const addChannelMutation = useAddChannel({
        onSuccess: () => {
            setLoading(false);
            refetch();
            toast.success("Chanel added successfully!");
            setData({ name: "", imageUrl: "", description: "" });
            setImageUrl("");
        },
        onError: (error) => {
            setLoading(false);
            toast.error("Error adding Channel ");
        },
        payload: data,
    });

    useEffect(() => {
        setData({ ...data, imageUrl: ImageUrl });
    }, [ImageUrl]);

    const handleSubmit = async () => {
        setLoading(true);
        setData({ ...data, imageUrl: ImageUrl });

        try {
            await addChannelMutation.mutateAsync();
            setTimeout(() => {
                setOpen(false);
            }, 2000);
        } catch (error) {
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <AnimatePresence>
            {open && (
                <motion.div className="fixed inset-0 flex items-center justify-center z-20 bg-[#00000033] bg-opacity-50">
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="bg-white shadow-lg z-40 relative rounded-[5px]  sm:w-[711px] w-[95%]"
                    >
                        <div className="flex flex-row justify-between items-center bg-[#D3EDB4] rounded-[5px] py-[15px] pr-[35px] pl-[18px] ">
                            <p className="font-light text-[16px] text-black">
                                قناه جديده
                            </p>
                            <img
                                src="/assets/images/close.svg"
                                alt="close"
                                onClick={() => {
                                    setOpen(false);
                                    setData({ name: "", description: "" });
                                    setImageUrl(null);
                                }}
                                className="cursor-pointer"
                            />
                        </div>
                        <Container>
                            <div className=" flex sm:flex-row flex-col gap-[20px]  justify-between p-4  py-[3rem]  ">
                                <div className=" flex-1">
                                        <BaseInput
                                            label={"اسم القناة"}
                                            value={data.name}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    name: e.target.value,
                                                })
                                            }
                                        />  
                                        <BaseTextarea
                                            value={data.description}
                                            onChange={(e) => {
                                                const updatedDescription =
                                                    e.target.value;
                                                setData({
                                                    ...data,
                                                    description:
                                                        updatedDescription,
                                                });
                                            }}
                                            label={"وصف القناة"}
                                        /> 
                                </div>
                                <div className=" w-[329px]  h-[247px]   flex flex-col gap-2 ">
                                    {" "}
                                    <InputImage
                                        ImageUrl={ImageUrl}
                                        setImageUrl={setImageUrl}
                                        type="ChannelImage"
                                    />
                                </div>
                            </div>
                            <div className=" flex flex-row  justify-end gap-[10px] p-[3rem] ">
                                {" "}
                                <Button
                                    onClick={() => handleSubmit()}
                                    variant="contained"
                                    disabled={loading}
                                >
                                    إضافة
                                </Button>
                                <Button
                                    onClick={() => {
                                        setOpen(false);
                                        setData({ name: "", description: "" });
                                        setImageUrl(null);
                                    }}
                                    variant="outlined"
                                >
                                    تراجع
                                </Button>
                            </div>
                        </Container>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default NewChannel;
