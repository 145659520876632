import React, { useRef, useState } from "react";
import NotificationImport from "./notification-import";

const Notification = () => {
    const [open, setOpen] = useState(false);
    const ref = useRef();

    return (
        <div className="bg-green p-2 w-fit rounded-full relative" ref={ref}>
            <span
                onClick={() => setOpen(!open)}
                className="w-1.5 h-1.5 bg-main rounded-full absolute top-1.5 right-2 z-10 "
            />
            <svg
                onClick={() => setOpen(!open)}
                width="17"
                className="relative"
                height="20"
                viewBox="0 0 17 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.2695 9.64534C15.539 8.79229 15.2071 8.05305 15.2071 6.79716V6.37013C15.2071 4.73354 14.8304 3.67907 14.0115 2.62459C12.7493 0.986993 10.6244 0 8.54423 0H8.45577C6.41935 0 4.36106 0.941671 3.077 2.5128C2.21333 3.58842 1.79293 4.68822 1.79293 6.37013V6.79716C1.79293 8.05305 1.48284 8.79229 0.730488 9.64534C0.176907 10.2738 0 11.0815 0 11.9557C0 12.8309 0.287226 13.6598 0.863666 14.3336C1.61602 15.1413 2.67846 15.6569 3.76375 15.7466C5.33505 15.9258 6.90634 15.9933 8.5005 15.9933C10.0937 15.9933 11.665 15.8805 13.2372 15.7466C14.3215 15.6569 15.384 15.1413 16.1363 14.3336C16.7118 13.6598 17 12.8309 17 11.9557C17 11.0815 16.8231 10.2738 16.2695 9.64534Z"
                    fill="#959895"
                />
                <path
                    opacity="0.4"
                    d="M10.5088 17.2283C10.0088 17.1215 6.96266 17.1215 6.46275 17.2283C6.03539 17.327 5.57324 17.5566 5.57324 18.0602C5.59809 18.5406 5.87935 18.9646 6.26895 19.2335L6.26795 19.2345C6.77184 19.6273 7.36319 19.877 7.98236 19.9667C8.31232 20.012 8.64825 20.01 8.99014 19.9667C9.60832 19.877 10.1997 19.6273 10.7036 19.2345L10.7026 19.2335C11.0922 18.9646 11.3734 18.5406 11.3983 18.0602C11.3983 17.5566 10.9361 17.327 10.5088 17.2283Z"
                    fill="#959895"
                />
            </svg>
            <NotificationImport open={open} setOpen={setOpen} reff={ref} />
        </div>
    );
};

export default Notification;
