import { useMutation, useQuery } from "react-query";
import { request } from "./request";
import useWebsiteStore from "../store/store";

const getAllNotifications = async (payload, token) => {
    const response = await request.post(
        "/api/c_panel/get_all_notifications",
        payload,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        }
    );
    return response.data;
};

const useGetAllNotifications = (payload) => {
    const token = useWebsiteStore((state) => state.token);
    return useQuery(["get-all-notifications", payload], () =>
        getAllNotifications(payload, token)
    );
};

const getAllConversation = async (id, token) => {
    const response = await request.post(
        `/api/conversation/get_by_id?id=${id}`,
        {
            IsPaginat: false,
            PageNumber: 1,
            PageSize: 10,
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return response.data;
};

const useGetAllConversation = (id) => {
    const token = useWebsiteStore((state) => state.token);
    return useQuery(
        ["get-all-conversation"],
        () => getAllConversation(id, token),
        {
            refetchOnMount: false, // Do not refetch on component mount
            refetchOnReconnect: false, // Do not refetch on reconnect
            refetchOnWindowFocus: false, // Do not refetch on window focus
        }
    );
};

const addNotification = async (data, token) => {
    const response = await request.post("/api/c_panel/add_notification", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
};

const useAddNotification = ({ onSuccess, onError, payload }) => {
    const token = useWebsiteStore((state) => state.token);

    return useMutation(
        ["add-notification", payload],
        () => addNotification(payload, token),
        {
            onSuccess,
            onError,
        }
    );
};

const getNotificationById = async (id, token) => {
 

    const response = await request.get(
        `/api/c_panel/get_notification?id=${id}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    return response.data;
};

const useGetNorificationById = (id) => {
    const token = useWebsiteStore((state) => state.token);

    return useQuery(["get-notification", id], () =>
        getNotificationById(id, token)
    );
};

const EnumTypeNotifications = {
    All: "For-All-User",
    Notification: "Notification",
    Imported: "Service",
};
export {
    useGetAllNotifications,
    EnumTypeNotifications,
    useAddNotification,
    useGetNorificationById,
    useGetAllConversation,
};
