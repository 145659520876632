import React from "react";

const Logo = () => {
  return (
    <div className="w-12 h-12 bg-main rounded-md flex justify-center items-center">
      <p className="text-white font-semibold">logo</p>
    </div>
  );
};

export default Logo;
