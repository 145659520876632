import { useMutation, useQuery } from "react-query";
import { request } from "./request";
import useWebsiteStore from "../store/store";

const addChannel = async (data, token) => {
  const response = await request.post("/api/c_panel/add_channel", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useAddChannel = ({ onSuccess, onError, payload }) => {
  const token = useWebsiteStore((state) => state.token);

  return useMutation(
    ["add-channel", payload],
    () => addChannel(payload, token),
    {
      onSuccess,
      onError,
    }
  );
};

export const updateChannel = async (channelId, payload, token) => {
  try {
    const response = await request.put(`/api/c_panel/update_chanel`, payload, {
      params: { channelId },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) { 
    throw error;
  }
};

const getAllChannel = async (payload, token) => {
  const response = await request.post(
    "/api/c_panel/get_all_channels",
    payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const useGetAllChannel = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-all-channels", payload], () =>
    getAllChannel(payload, token)
  );
};

const getChannelById = async (payload, token) => {
  const response = await request.post(
    "/api/c_panel/get_channel",
    {},
    {
      params: payload,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};
const useGetChannelById = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-channel", payload], () =>
    getChannelById(payload, token)
  );
};

const deleteChannel = async (channelId, token) => {
  try {
    const response = await request.delete("/api/c_panel/delete_chanel", {
      params: { channelId },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) { 
    throw error;
  }
};
const useDeleteChannel = ({ onSuccess, onError, channelId }) => {
  const token = useWebsiteStore((state) => state.token);

  return useMutation(
    ["delete-channel", channelId],
    () => deleteChannel(channelId, token),
    {
      onSuccess,
      onError,
    }
  );
};

const sendInChannel = async (data, token) => {
  try {
    const response = await request.post("/api/c_panel/send_in_channel", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) { 
    throw error;
  }
};
const useSendInChannel = ({ onSuccess, onError, data }) => {
  const token = useWebsiteStore((state) => state.token);
  return useMutation(
    ["send-in-channel", data],
    () => sendInChannel(data, token),
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useAddChannel,
  useGetAllChannel,
  useGetChannelById,
  useDeleteChannel,
  useSendInChannel,
};
