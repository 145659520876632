import React from "react";
import Pagination from "@mui/material/Pagination";

const PaginationCom = ({ setPage, page, count }) => {
  const handleChange = (_event, value) => {
    setPage(value);
  };
  return (
    <div className="flex justify-center w-full py-[25px]">
      <Pagination
        color="primary"
        dir="ltr"
        count={count}
        page={page}
        onChange={handleChange}
      />
    </div>
  );
};

export default PaginationCom;
