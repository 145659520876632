import React, { useRef } from "react";
import useOutsideClick from "../../utils/useOut";
import useSelectMenuScroll from "../../utils/helpers";
import NotificationImportCard from "./notification-import-card";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { useGetAllNotifications } from "../../hook/notification";
import { useNavigate } from "react-router";

import { AnimatePresence, motion } from "framer-motion";

const NotificationImport = ({ open, setOpen, reff }) => {
    const ref = useRef();
    const router = useNavigate();
    const { data } = useGetAllNotifications({
        type: "All",
        IsPaginat: true,
        PageNumber: 1,
        PageSize: 6,
    });
    useSelectMenuScroll(ref, open, setOpen);
    useOutsideClick([ref, reff], () => setOpen(false));
    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="absolute rounded-md z-50 shadow-md  py-4 flex flex-col top-[101%] left-[200px] -translate-x-1/2 min-w-[400px] h-[500px]  bg-white"
                    ref={ref}
                >
                    <h1 className="py-2  p-4 text-main font-semibold">
                        الاشعارات الواردة
                    </h1>
                    <div className="h-full overflow-auto">
                        {data?.data?.map((e) => (
                            <NotificationImportCard
                                key={e.id}
                                title={e.title}
                                date={e.date}
                                refrenceId={e.refrenceId}
                                setOpen={setOpen}
                                e={e}
                            />
                        ))}
                    </div>
                    <div className=" p-2 px-6">
                        <div className="flex w-full text-sm px-2 justify-between items-center">
                            <p>تنبيه الاشعارات</p>
                            {/* <div>
                                    <FormControlLabel
                                        control={<Switch defaultChecked />}
                                    />{" "}
                                    <span className="text-[10px] text-black/60">
                                        كتم الاشعارات
                                    </span>
                                </div> */}
                        </div>
                        <div className=" mx-auto mt-2">
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                    router("/imported");
                                    setOpen(false);
                                }}
                            >
                                رؤية كل الاشعارات
                            </Button>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default NotificationImport;
