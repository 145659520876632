import React, { useRef, useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import useSelectMenuScroll from "../utils/helpers";
import useOutsideClick from "../utils/useOut";
import useWebsiteStore from "../store/store";

const Select = ({ selected, setSelected, data = [], ...props }) => {
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState(data[0]?.name);
  const ref = useRef();
  const reff = useRef();
  useSelectMenuScroll(ref, open, setOpen);
  useOutsideClick([ref, reff], () => {
    setOpen(false);
  });

  return (
    <div
      className="bg-white border h-[40px] w-full  min-w-[180px] cursor-pointer border-input px-4 py-2 relative rounded-md text-black/60"
      onClick={() => setOpen(!open)}
      ref={ref}
      {...props}
    >
      <div className="w-full h-full flex justify-between gap-6 items-center">
        <p className="text-sm">{value}</p> <IoChevronDown />
      </div>
      {open && (
        <div
          className=" absolute top-[101%] left-0 bg-white w-full rounded-md p-2 shadow-md z-20 "
          ref={reff}
        >
          {data?.map((e) => (
            <p
              key={e.id}
              className="p-3 rounded-sm whitespace-nowrap text-xs hover:bg-slate-50 transition-all cursor-pointer"
              onClick={() => {
                setSelected(e.id);
                setValue(e.name);
              }}
            >
              {e?.name}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
