import React from "react";

const Button = ({ children, title }) => {
  return (
    <button className="  bg-main rounded-[10px] hover:bg-[#0077ad] hover:scale-[0.99] transition-all border border-1 whitespace-nowrap text-white w-fit  text-[14px]  font-medium    py-[10px] px-[20px]">
      {children ?? title}
    </button>
  );
};

export default Button;
