import { useEffect, useState } from "react";

export function getCoords(elem) {
    if (typeof document !== "undefined") {
        const box = elem?.getBoundingClientRect();

        const body = typeof document !== "undefined" && document?.body;
        const docEl =
            typeof document !== "undefined" && document?.documentElement;

        const scrollTop =
            (typeof window !== "undefined" && window.pageYOffset) ||
            (docEl && docEl.scrollTop) ||
            (body && body.scrollTop) ||
            0;
        const scrollLeft =
            (typeof window !== "undefined" && window.pageXOffset) ||
            (docEl && docEl.scrollLeft) ||
            (body && body.scrollLeft) ||
            0;

        const clientTop =
            (docEl && docEl.clientTop) || (body && body.clientTop) || 0;
        const clientLeft =
            (docEl && docEl.clientLeft) || (body && body.clientLeft) || 0;

        const top =
            (box?.top || 0) +
            (body && body.style.overflow === "hidden"
                ? 0
                : (scrollTop || 0) - clientTop) +
            (box?.height || 0);
        const left = ((box && box.left) || 0) + scrollLeft - clientLeft;

        return { top: Math.round(top), left: Math.round(left) };
    }
    return { top: 0, left: 0 };
}
const useSelectMenuScroll = (selectWrapperRef, open, setOpen) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(selectWrapperRef.current?.offsetWidth || 0);
    }, [selectWrapperRef, selectWrapperRef.current?.offsetWidth]);

    useEffect(() => {
        const handleWheel = (e) => {
            if (!selectWrapperRef.current?.contains(e.target)) {
                setOpen(false);
            }
        };

        window.addEventListener("wheel", handleWheel);
        window.addEventListener("touchmove", handleWheel);

        return () => {
            window.removeEventListener("wheel", handleWheel);
            window.removeEventListener("touchmove", handleWheel);
        };
    }, [open, selectWrapperRef, setOpen]);

    return {
        width,
        ...getCoords(selectWrapperRef.current),
    };
};
export const getFromSessionStorage = (key) => {
    const data = typeof window !== "undefined" && sessionStorage.getItem(key);
    if (!data) return null;

    return JSON.parse(data);
};

export const saveToSessionStorage = (key, value) => {
    typeof window !== "undefined" &&
        sessionStorage.setItem(key, JSON.stringify(value));
};
export const key = "chat app by LATIFA";
export default useSelectMenuScroll;
