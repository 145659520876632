import React from "react";
import Notification from "./notification";
import { useGetMain } from "../../hook/main";
import { baseURL } from "../../hook/request";

import { IoPersonCircle } from "react-icons/io5";
const Header = () => {
    const { data } = useGetMain({});

    return (
        <div className="flex w-full justify-between items-center bg-white py-2 sm:px-6 px-2 gap-1">
            <div className="relative w-full max-w-[1000px]  overflow-hidden rounded-full">
                <div className="absolute bg-[#f6fbf0] left-3 top-1/2 -translate-y-1/2  ">
                    <svg
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.943 10.35L15.4788 13.725C15.7931 14.025 15.7931 14.475 15.4788 14.775C15.3217 14.925 15.086 15 14.9288 15C14.7717 15 14.5359 14.925 14.3788 14.775L10.843 11.4C9.66444 12.225 8.25013 12.75 6.67868 12.75C2.98576 12.75 0 9.9 0 6.375C0 2.85 2.98576 0 6.67868 0C10.3716 0 13.3574 2.85 13.3574 6.375C13.3574 7.875 12.8073 9.3 11.943 10.35ZM6.67874 1.50018C3.85012 1.50018 1.57152 3.67518 1.57152 6.37518C1.57152 9.07518 3.85012 11.2502 6.67874 11.2502C8.09305 11.2502 9.35021 10.7252 10.2931 9.82518C11.236 8.92518 11.786 7.72518 11.786 6.37518C11.786 3.67518 9.50736 1.50018 6.67874 1.50018Z"
                            fill="#0189C7"
                        />
                    </svg>
                </div>
                <input
                    type="text"
                    className="bg-[#f6fbf0] outline-none w-full  py-2 text-slate-500 rounded-full  px-4"
                />
            </div>
            <div className="flex gap-2 items-center ">
                <Notification />
                <div className="w-[33px] h-[33px]">
                    {data?.data.profileImageUrl ? (
                        <img
                            src={`${baseURL}${data?.data.profileImageUrl}`}
                            alt=""
                            className="w-full h-full rounded-full"
                        />
                    ) : (
                        <IoPersonCircle className="w-full h-full rounded-full" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
