import { Button, Container } from '@mui/material';
import React, { useEffect, useState } from 'react'

import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import BaseInput from '../base-input';
import BaseTextarea from '../base-textarea';
import InputImage from '../input-image';
import { useAddGift } from '../../hook/gift';
const AddGIft = ({ open, setOpen, refetch }) => {
    const [imageUrl, setImageUrl] = useState(null);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        name: "",
        description: "",
        price:0
    });

    const addGiftMutation = useAddGift({
        onSuccess: () => {
            refetch();
            toast.success("Gift added successfully!");
            setData({
                name: "",
                description: "",
                price: 0,
                imageUrl:""
            });
            setImageUrl("");

        },
        onError: (error) => {
            toast.error("Error adding Gift");
        },
        payload: data,
    });

    const handleSubmit = async () => {
 
        setLoading(true);
        setData({ ...data, imageUrl: imageUrl });
        try {
            await addGiftMutation.mutateAsync();
            setTimeout(() => {
                setOpen(false);
            }, 2000);
        } catch (error) {
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        setData({ ...data, imageUrl: imageUrl });
    }, [imageUrl]);
  return (
    <AnimatePresence>
    {open && (
        <motion.div className="fixed inset-0 flex items-center justify-center z-20 bg-[#00000033] bg-opacity-50">
            <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="bg-white shadow-lg z-40 relative rounded-[5px]  sm:w-[711px] w-[95%]"
            >
                <div className="flex flex-row justify-between items-center bg-[#D3EDB4] rounded-[5px] py-[15px] pr-[35px] pl-[18px] ">
                    <p className="font-light text-[16px] text-black">
                        هدية جديد
                    </p>
                    <img
                        src="/assets/images/close.svg"
                        alt="close"
                        onClick={() => {
                            setOpen(false);
                            setData({ title: "", description: "" ,price:""});
                            setImageUrl(null);
                        }}
                        className="cursor-pointer"
                    />
                </div>
                <Container>
                    <div className=" flex sm:flex-row flex-col gap-5   justify-between p-4  py-[3rem]  ">
                        <div className=' flex-1'>
                               <BaseInput
                                    label={"اسم الهدية "}
                                    value={data.name}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name: e.target.value,
                                        })
                                    }
                                />  
                                <BaseInput
                                    label={"سعر الهدية "}
                                    value={data.price}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                              price: parseInt(e.target.value, 10), 
                                        })
                                    }
                                    type="number"
                                />  
                                <BaseTextarea
                                    value={data.description}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            description: e.target.value,
                                        })
                                    }
                                    label={"وصف الهدية"}
                                /> 
                        </div>
                        <div className=" w-[329px]  h-[247px]   flex flex-col gap-2 ">
                            {" "}
                            <div className=" w-[329px]  h-[247px]   flex flex-col gap-2 ">
                                {" "}
                                <InputImage
                                    ImageUrl={imageUrl}
                                    setImageUrl={setImageUrl}
                                    type="GiftImage"
                                />
                            </div>
                        </div>
                    </div>
                    <div className=" flex flex-row  justify-end gap-[10px] p-[3rem] ">
                        {" "}
                        <Button
                            onClick={() => handleSubmit()}
                            variant="contained"
                            disabled={loading}
                        >
                            إضافة
                        </Button>
                        <Button
                            onClick={() => {
                                setOpen(false);
                                setData({ title: "", description: "" ,price:""});
                                setImageUrl(null);
                            }}
                            variant="outlined"
                        >
                            تراجع
                        </Button>
                    </div>
                </Container>
            </motion.div>
        </motion.div>
    )}
</AnimatePresence>
  )
}

export default AddGIft