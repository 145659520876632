import React from "react";

import { motion } from "framer-motion";
const Card = ({ postCards, number }) => {
    return (
        <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.4 }}
            className="flex flex-col justify-center items-center  shadow-md max-sm:mx-auto rounded-[6px] max-w-[230px] bg-white border-[6] w-full relative overflow-hidden"
        >
            <div className="py-2 px-6">
                <div
                    style={{ backgroundColor: postCards?.color }}
                    className="p-2 w-fit rounded-full flex justify-center items-center"
                >
                    <span
                        dangerouslySetInnerHTML={{ __html: postCards?.icon }}
                    ></span>
                </div>
            </div>
            <p className="text-[24px] font-semibold text-paragraph-light px-6 py-1">
                {number}
            </p>
            <p className="text-[14px] font-almarai text-paragraph-light font-normal px-6 py-1">
                {postCards?.title}
            </p>
        </motion.div>
    );
};

export default Card;
