import React, { PureComponent } from "react";
import {
    LineChart,
    Line,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import StaticHeader from "./static-header";

export default class Statistic extends PureComponent {
    render() {
        const analyticsUser = this.props.data?.data?.analyticsUser;
        const pointsSum = analyticsUser?.reduce(
            (acc, current) => acc + current.pionts,
            0
        );
        const levelsSum = analyticsUser?.reduce(
            (acc, current) => acc + current.level,
            0
        );

        const data = analyticsUser?.map((user) => {
            return {
                name: user.name,
                النقاط: user.pionts,
                level: user.level,
            };
        });
        return (
            <div
                className=" md:w-[80%] w-full md:min-h-[280px] min-h-[100px]"
                style={{
                    backgroundColor: "#fff",
                    padding: 10,
                    borderRadius: 6,
                }}
            >
                <StaticHeader
                    from={this.props.from}
                    setFrom={this.props.setFrom}
                    to={this.props.to}
                    setTo={this.props.setTo}
                    pointsSum={pointsSum}
                    levelsSum={levelsSum}
                />
                {data && data.length > 0 ? (
                    <ResponsiveContainer aspect={2.5}>
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="1 1" />
                            <Tooltip
                                labelFormatter={(value) => {
                                    if (data && data[value] !== undefined) {
                                        return data[value].name;
                                    }
                                    return "";
                                }}
                            />
                            <YAxis
                                allowDecimals={false}
                                tickCount={5}
                                axisLine={{ strokeWidth: 0 }}
                                padding={{ left: 30, right: 30 }}
                                orientation="right"
                                tick={{
                                    style: {
                                        transform: "translate(40px, -5qpx)",
                                    },
                                    fontSize: 14,
                                }}
                            />
                            <Tooltip />
                            <Line
                                type="monotone"
                                dataKey="النقاط"
                                stroke="#82B941"
                                activeDot={{ r: 4 }}
                                strokeWidth={3}
                            />
                            <Line
                                type="monotone"
                                dataKey="level"
                                strokeWidth={3}
                                stroke="#FFE500"
                            />
                        </LineChart>
                    </ResponsiveContainer>
                ) : (
                    <div className="text-paragraph-light text-[22px] font-light text-center  ">
                        لا يوجد بيانات
                    </div>
                )}
            </div>
        );
    }
}
