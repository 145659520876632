import React from "react";

const BaseTextarea = ({ label, ...props }) => {
    return (
        <div className=" flex flex-col gap-2 ">
            <p className="  font-light text-[12px]   ">{label}</p>
            <textarea
                type="text"
                {...props}
                className="  focus:placeholder:opacity-0 focus:opacity-90 transition-all placeholder:transition-all outline-none  py-[7px] px-[14px] border   border-1  rounded-[5px]  border-light  min-h-[172px] max-h-[172px]  w-full "
            />
        </div>
    );
};

export default BaseTextarea;
