import React, { useEffect, useState } from "react";
import Message from "./message";
import { AiFillLike } from "react-icons/ai";
import { FaSmile } from "react-icons/fa";
import { useParams } from "react-router";
import { useGetChannelById, useSendInChannel } from "../../hook/channel";
import { CircularProgress } from "@mui/material";
import { BsSendFill } from "react-icons/bs";
import Image from "./image";
import { baseURL } from "../../hook/request";
import SingleMessage from "./singleMessage";
import { toast } from "react-toastify";
const ChannelInterface = () => {
    const { id } = useParams();
    const [imgUrls, setImageUrl] = useState();
    const [loadingg, setloadingg] = useState(false);
    const [display, setDisplay] = useState(false);
    const [sent, setSent] = useState(false);
    const [data, setData] = useState({
        channelId: id,
        imageUrls: "",
        isImage: false,
        text: "",
    });
    const {
        data: info,
        isLoading,
        refetch,
    } = useGetChannelById({
        channelId: id,
    });
    useEffect(() => {
        if (info && !isLoading) {
            setDisplay(false);
        }
        const element = document.getElementById("chat");
        if (element) {
            element.scrollTop = element.scrollHeight - element.clientHeight;
        }
    }, [info, isLoading, id]);

    useEffect(() => {
        const element = document.getElementById("chat");
        if (element) {
            element.scrollTop = element.scrollHeight - element.clientHeight;
        }
    }, [info, sent, display]);

    const { mutate: sendInChannelMutate } = useSendInChannel({
        onSuccess: () => {
            setloadingg(false);
            refetch();
            setData((prevData) => ({
                ...prevData,
                imageUrls: [],
                isImage: false,
                text: "",
            }));
            setSent(true);
        },
        onError: (error) => {
            setloadingg(false);
        },
        data,
    });

    const handleSend = () => {
        if (!data.text) {
            toast.error("الرجاء ادخال نص للارسال");
            return;
        }
        setDisplay(true);
        setloadingg(true);
        sendInChannelMutate(data);
    };

    useEffect(() => {
        setData((prevData) => ({
            ...prevData,
            imageUrls: imgUrls ? [imgUrls] : [],
            isImage: !!imgUrls,
        }));
    }, [imgUrls]);
    return (
        <>
            {isLoading ? (
                <div className="w-full flex justify-center items-center h-[50vh]">
                    {" "}
                    <CircularProgress />
                </div>
            ) : (
                <div className="flex flex-col h-[calc(100vh-56px)] w-full border-e overflow-auto border-slate-200 pb-2 ">
                    <div className="flex items-center w-full   justify-start px-6 py-4 border-b border-slate-200 ">
                        <div className="  w-full  h-full flex flex-row   justify-start gap-5 items-center ">
                            {" "}
                            <div className=" object-cover overflow-hidden  w-[60px] h-[56px] items-center   rounded-full">
                                <img
                                    src={`${baseURL}${info?.data?.imgUrl}`}
                                    className="   w-full h-full     "
                                    alt=""
                                />{" "}
                            </div>
                            <p className=" font-semibold text-[14px] ">
                                {" "}
                                {info?.data?.name}{" "}
                            </p>
                        </div>
                    </div>
                    <div
                        id="chat"
                        className="h-full overflow-auto relative md:pt-4  md:px-6 px-1 pt-2"
                    >
                        <div className="relative  w-full flex flex-col gap-2">
                            {info?.data?.messages
                                .slice()
                                .reverse()
                                .map((message, index) => (
                                    <Message message={message} key={index} />
                                ))}
                            {display && (
                                <SingleMessage
                                    text={data.text}
                                    imageUrls={data.imageUrls}
                                    loadingg={loadingg}
                                    display={display}
                                    handleSend={handleSend}
                                    sent={sent}
                                />
                            )}
                        </div>
                    </div>{" "}
                    <div className="w-full bg-white relative py-4 px-4 flex items-center  sm:gap-4 gap-1">
                        <button onClick={handleSend} disabled={loadingg}>
                            <BsSendFill
                                size={20}
                                className="text-xl cursor-pointer"
                                fill="#0189C7"
                            />
                        </button>
                        <AiFillLike
                            size={25}
                            className="text-xl cursor-pointer"
                            fill="#0189C7"
                        />
                        <div className="border rtl py-2 bg-[#F8F8F8] items-center text-black/70   w-full ltr border-stone-200 px-4 rounded-full  flex flex-row ">
                            <input
                                type="text"
                                className="outline-none rtl  h-[24px]  resize-none placeholder:items-center pr-5 bg-[#F8F8F8] text-black/70 focus:placeholder:opacity-0 w-full placeholder:transition-all"
                                placeholder="       Aa"
                                value={data.text}
                                onChange={(e) =>
                                    setData({
                                        ...data,
                                        text: e.target.value,
                                    })
                                }
                            />{" "}
                            <FaSmile size={26} fill="#0189C7" />
                        </div>
                        <div>
                            <Image
                                ImageUrl={imgUrls}
                                setImageUrl={setImageUrl}
                                type="ChannelImage"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ChannelInterface;
