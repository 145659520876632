import React from "react";
import { useParams } from "react-router";
import { useGetPointsRegister } from "../../hook/users";
// import useWebsiteStore from "../../store/store";
import { CircularProgress } from "@mui/material";

const PointsRecords = () => {
    const { id } = useParams();
    const { data, isLoading } = useGetPointsRegister(id, {
        IsPaginat: true,
        PageNumber: 1,
        PageSize: 10,
    });

    return (
        <>
            {isLoading ? (
                <div className="w-full flex justify-center items-center h-[50vh]">
                    {" "}
                    <CircularProgress />
                </div>
            ) : (
                <div className="w-full mt-6 overflow-auto flex ">
                    {" "}
                    <table className="w-full text-center min-w-[900px] ">
                        <thead className="text-[#404D61] text-sm  ">
                            <tr className="bg-white h-[50px] cursor-pointer hover:bg-slate-50 transition-all text-[#6E6B7B] text-sm ">
                                <th>التاريخ والوقت </th>
                                <th> نوع الحركه</th>
                                <th> النقاط</th>
                            </tr>
                            <tr className="h-[10px]" />
                        </thead>
                        <tbody>
                            {data?.data?.map((item, index) => (
                                <>
                                    <tr className="bg-white h-[45px] cursor-pointer hover:bg-slate-50 transition-all text-[#6E6B7B] text-sm">
                                        <td>{item.date}</td>
                                        <td style={{
                                            color: item.typeTransaction === "Addition" ? "green" : "red"
                                            }}>
                                            {item.typeTransaction}
                                            </td>
                                        <td>{item.value} نقطة</td>
                                    </tr>
                                    <tr className="h-[10px]" />
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default PointsRecords;
