import React, { useEffect } from "react";
import { getFromSessionStorage, key } from "./utils/helpers";
import { useNavigate } from "react-router";

function TestAuth({ children }) {
  const auth = getFromSessionStorage(key);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate("/login");
    }
  }, [auth, navigate]);

  if (!auth) {
    return <div />;
  }
  return <>{children}</>;
}

export default TestAuth;
