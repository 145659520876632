import { useQuery } from "react-query";
import useWebsiteStore from "../store/store";
import { request } from "./request";

const getAllPost = async (payload, token) => {
    const response = await request.post("/api/c_panel/get_all_post", payload, {
        headers: { Authorization: `Bearer ${token}` },
        "Content-Type": "multipart/form-data",
    });
    return response.data;
};

const useGetAllPost = (payload) => {
    const token = useWebsiteStore((state) => state.token);
    return useQuery(["get-all-post", payload], () =>
        getAllPost(payload, token)
    );
};

const getPostById = async (payload, token) => {
    const response = await request.get("/api/c_panel/get_post", {
        params: payload,
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
};
const useGetPostById = (payload) => {
    const token = useWebsiteStore((state) => state.token);
    return useQuery(["get-post", payload], () => getPostById(payload, token));
};

export const updatePost = async (id, isPin, token) => {
    try {
        const response = await request.put(
            `/api/c_panel/update_post`,
            {
                id,
                isPin,
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return response.data;
    } catch (error) { 
        throw error;
    }
};

export { useGetAllPost, useGetPostById };
