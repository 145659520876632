import React, { useState } from "react";
import EditChannel from "./edit-channel";
import { useNavigate } from "react-router";
import { baseURL } from "../../hook/request";

import { motion } from "framer-motion";
const ChannelItem = ({
    imageUrl,
    name,
    channelId,
    refetch,
    dataCreated,
    transition,
}) => {
    const router = useNavigate();
    const [open, setOpen] = useState(false);
    return (
        <>
            <EditChannel
                refetch={refetch}
                open={open}
                setOpen={setOpen}
                channelId={channelId}
            />

            <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: transition / 10 + 0.3 }}
                className=" bg-white rounded-[10px]   h-[287px] "
            >
                <div className=" h-[180px] overflow-hidden  flex items-center  justify-center">
                    {" "}
                    <img
                        onClick={() => router(`/channel/chat/${channelId}`)}
                        src={`${baseURL}${imageUrl}`}
                        className="rounded-[10px] object-cover items-center w-full h-full  m-auto cursor-pointer"
                        alt=""
                    />
                </div>
                <div className=" p-[20px] flex flex-col gap-[10px]  ">
                    <p className="  font-semibold texet-[14px]  text-paragraph-dark font-almarai">
                        {name}
                    </p>
                    <p className="  font-normal  whitespace-nowrap  text-[12px] text-paragraph-dark font-almarai">
                        {" "}
                        تاريخ الاضافة {dataCreated}
                    </p>
                </div>
                <button
                    onClick={() => setOpen(true)}
                    className=" font-almarai  bg-main rounded-[10px] border border-1 whitespace-nowrap text-white w-full h-[41px]   text-[14px]  font-medium    py-[10px] px-[20px]"
                >
                    تعديل
                </button>
            </motion.div>
        </>
    );
};

export default ChannelItem;
