const cards = [
    {
        number: "38.4K",
        title: "عدد المنشورات",
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.3 0.600049L21.3 4.60005C22.4 5.10005 23 6.10005 23 7.20005V16.7C23 17.9 22.3 18.9 21.3 19.4L13.3 23.4C12.9 23.6 12.5 23.7 12 23.7C11.6 23.7 11.1 23.6 10.7 23.4L2.7 19.4C1.6 18.9 1 17.8 1 16.7V7.20005C1 6.10005 1.6 5.10005 2.6 4.60005L10.6 0.600049C11.5 0.200049 12.5 0.200049 13.3 0.600049ZM12 2.20005C11.9 2.20005 11.7 2.20005 11.6 2.30005L4.2 6.00005L12 9.90005L19.8 6.00005L12.4 2.30005C12.3 2.20005 12.2 2.20005 12 2.20005ZM3.5 17.7001C3.2 17.5 3 17.1 3 16.8V7.60005L11 11.6V21.4L3.5 17.7001ZM13 21.4L20.4 17.7001C20.8 17.5 21 17.2 21 16.8V7.60005L13 11.6V21.4Z" fill="black"/>
<mask id="mask0_659_8904" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="0" width="22" height="24">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.3 0.600049L21.3 4.60005C22.4 5.10005 23 6.10005 23 7.20005V16.7C23 17.9 22.3 18.9 21.3 19.4L13.3 23.4C12.9 23.6 12.5 23.7 12 23.7C11.6 23.7 11.1 23.6 10.7 23.4L2.7 19.4C1.6 18.9 1 17.8 1 16.7V7.20005C1 6.10005 1.6 5.10005 2.6 4.60005L10.6 0.600049C11.5 0.200049 12.5 0.200049 13.3 0.600049ZM12 2.20005C11.9 2.20005 11.7 2.20005 11.6 2.30005L4.2 6.00005L12 9.90005L19.8 6.00005L12.4 2.30005C12.3 2.20005 12.2 2.20005 12 2.20005ZM3.5 17.7001C3.2 17.5 3 17.1 3 16.8V7.60005L11 11.6V21.4L3.5 17.7001ZM13 21.4L20.4 17.7001C20.8 17.5 21 17.2 21 16.8V7.60005L13 11.6V21.4Z" fill="white"/>
</mask>
<g mask="url(#mask0_659_8904)">
<rect width="24" height="24" fill="#FF9F43"/>
</g>
</svg>

`,
        line: `<svg width="233" height="71" viewBox="0 0 233 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.570312 44.7534C0.570312 44.7534 8.20142 43.198 15.5622 30.4747C22.923 17.7514 29.4587 7.71478 29.4587 7.71478C29.4587 7.71478 39.4296 -5.24408 47.4562 7.71478C55.4828 20.6736 71.5425 58.4976 71.5425 58.4976C71.5425 58.4976 80.1993 68.9798 88.8652 51.7185C97.531 34.4573 108.454 8.81373 108.454 8.81373C108.454 8.81373 115.981 -5.48024 125.491 10.3685C135.002 26.2173 141.986 51.7185 141.986 51.7185C141.986 51.7185 152.452 84.5296 166.612 60.9856C180.772 37.4417 180.89 36.0286 180.89 36.0286C180.89 36.0286 193.581 15.6373 209.144 38.7825C224.707 61.9278 232.663 62.1912 232.663 62.1912" stroke="#FF9920" stroke-width="2"/>
</svg>
`,
        fill: `<svg width="233" height="79" viewBox="0 0 233 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M0.570312 43.7534C0.570312 43.7534 8.20142 42.198 15.5622 29.4747C22.923 16.7514 29.4587 6.71478 29.4587 6.71478C29.4587 6.71478 39.4296 -6.24408 47.4562 6.71478C55.4828 19.6736 71.5425 57.4976 71.5425 57.4976C71.5425 57.4976 80.1993 67.9798 88.8652 50.7185C97.531 33.4573 108.454 7.81373 108.454 7.81373C108.454 7.81373 115.981 -6.48025 125.491 9.36852C135.002 25.2173 141.986 50.7185 141.986 50.7185C141.986 50.7185 152.452 83.5296 166.612 59.9856C180.772 36.4417 180.89 35.0286 180.89 35.0286C180.89 35.0286 193.581 14.6373 209.144 37.7825C224.707 60.9278 232.663 61.1912 232.663 61.1912V78.933H0.570312V43.7534Z" fill="url(#paint0_linear_659_8898)"/>
<defs>
<linearGradient id="paint0_linear_659_8898" x1="0.570312" y1="0.235352" x2="0.570312" y2="78.933" gradientUnits="userSpaceOnUse">
<stop stop-color="#FEC5C6"/>
<stop offset="1" stop-color="#EEEEEE" stop-opacity="0.01"/>
</linearGradient>
</defs>
</svg>
`,
        color: "#FF9F431F",
    },
    {
        number: "38.4K",
        title: "عدد المستخدمين",
        icon: `<svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.54946 2H1.36914C0.816856 2 0.369141 1.55228 0.369141 1C0.369141 0.447715 0.816856 0 1.36914 0H5.36914C5.84577 0 6.25615 0.336385 6.34969 0.803743L7.18957 5H23.3691C23.9965 5 24.469 5.57104 24.3514 6.18733L22.75 14.5848C22.4669 16.0102 21.2029 17.0277 19.7691 17L10.0683 16.9998C8.61538 17.0277 7.35141 16.0102 7.06859 14.5863L5.39793 6.23921C5.39115 6.21159 5.38552 6.18353 5.38109 6.15508L4.54946 2ZM7.36914 21.0016C7.36914 22.2026 8.16849 23.0033 9.36751 23.0033C10.5665 23.0033 11.3659 22.2026 11.3659 21.0016C11.3659 19.8007 10.5665 19 9.36751 19C8.16849 19 7.36914 19.8007 7.36914 21.0016ZM18.3691 21C18.3691 22.2 19.1685 23 20.3675 23C21.5665 23 22.3659 22.2 22.3659 21C22.3659 19.8 21.5665 19 20.3675 19C19.1685 19 18.3691 19.8 18.3691 21ZM9.02998 14.1952L7.58987 7H22.1604L20.7868 14.2027C20.6939 14.6703 20.2726 15.0095 19.7883 15.0002L10.0491 15C9.54567 15.0095 9.12435 14.6703 9.02998 14.1952Z" fill="#EA5455"/>
               </svg>`,
        line: `<svg width="100%" height="73" viewBox="0 0 232 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1 36.0947C1 36.0947 17.0388 33.6211 26.7766 19.5281C36.5144 5.43507 52.1215 4.36151 61.1668 17.2042C70.212 30.0468 80.1853 45.5597 80.1853 45.5597C80.1853 45.5597 94.9306 60.0527 110.712 45.5597C126.494 31.0667 129.397 29.1409 129.397 29.1409C129.397 29.1409 142.948 20.8614 152.357 34.6691C161.766 48.4768 176.086 67.21 176.086 67.21C176.086 67.21 188.86 79.811 197.561 62.8226C206.263 45.8342 221.624 2.8799 232.101 1.42847" stroke="#FD444E" stroke-width="2"/>
               </svg>`,
        fill: `<svg width="100%" height="75" viewBox="0 0 232 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M0 35.0947C0 35.0947 16.0341 32.6211 25.7691 18.5281C35.5041 4.43507 51.1066 3.36151 60.1492 16.2042C69.1919 29.0468 79.1622 44.5597 79.1622 44.5597C79.1622 44.5597 94.4114 58.5944 109.68 44.5597C124.95 30.525 128.359 28.1409 128.359 28.1409C128.359 28.1409 142.756 18.107 151.313 33.6691C159.87 49.2312 175.035 66.21 175.035 66.21C175.035 66.21 187.805 78.811 196.504 61.8226C205.203 44.8343 220.56 1.8799 231.034 0.428467C231.168 15.8291 231.034 74.4211 231.034 74.4211H0V35.0947Z" fill="url(#paint0_linear_659_8921)"/>
                 <defs>
                   <linearGradient id="paint0_linear_659_8921" x1="0" y1="0.428467" x2="0" y2="74.4211" gradientUnits="userSpaceOnUse">
                     <stop stop-color="#FD444E"/>
                     <stop offset="1" stop-color="white" stop-opacity="0.01"/>
                   </linearGradient>
                   </defs>
                   </svg>`,
        color: "#EA54551F",
    },
    {
        number: "38.4K",
        title: "عدد الخدمات",
        icon: `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.1855 3H3.18555C1.48555 3 0.185547 4.3 0.185547 6V18C0.185547 19.7 1.48555 21 3.18555 21H21.1855C22.8855 21 24.1855 19.7 24.1855 18V6C24.1855 4.3 22.8855 3 21.1855 3ZM3.18555 5H21.1855C21.7855 5 22.1855 5.4 22.1855 6V9H2.18555V6C2.18555 5.4 2.58555 5 3.18555 5ZM3.18555 19H21.1855C21.7855 19 22.1855 18.6 22.1855 18V11H2.18555V18C2.18555 18.6 2.58555 19 3.18555 19Z" fill="black"/>
<mask id="mask0_659_8950" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="3" width="25" height="18">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.1855 3H3.18555C1.48555 3 0.185547 4.3 0.185547 6V18C0.185547 19.7 1.48555 21 3.18555 21H21.1855C22.8855 21 24.1855 19.7 24.1855 18V6C24.1855 4.3 22.8855 3 21.1855 3ZM3.18555 5H21.1855C21.7855 5 22.1855 5.4 22.1855 6V9H2.18555V6C2.18555 5.4 2.58555 5 3.18555 5ZM3.18555 19H21.1855C21.7855 19 22.1855 18.6 22.1855 18V11H2.18555V18C2.18555 18.6 2.58555 19 3.18555 19Z" fill="white"/>
</mask>
<g mask="url(#mask0_659_8950)">
<rect x="0.185547" width="24" height="24" fill="#28C76F"/>
</g>
</svg>

`,
        line: `<svg width="234" height="63" viewBox="0 0 234 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.12451 36.4607C1.12451 36.4607 11.5823 38.6813 17.575 46.1096C23.5677 53.5379 27.049 55.6592 27.049 55.6592C27.049 55.6592 39.9774 69.5221 50.1309 54.3223C60.2844 39.1225 67.2919 26.8007 67.2919 26.8007C67.2919 26.8007 78.7201 11.3493 90.7772 26.8007C102.834 42.252 107.605 47.1386 107.605 47.1386C107.605 47.1386 116.236 57.0599 129.601 48.8666C142.966 40.6734 145.216 38.7522 145.216 38.7522C145.216 38.7522 157.781 32.4802 168.261 40.6734C178.741 48.8666 186.92 51.6986 186.92 51.6986C186.92 51.6986 199.094 56.1275 204.94 43.6847C210.786 31.2419 222.923 -0.413891 233.234 1.87414" stroke="#00CB65" stroke-width="2"/>
</svg>

`,
        fill: `<svg width="233" height="71" viewBox="0 0 233 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M0.124512 35.4607C0.124512 35.4607 10.5823 37.6813 16.575 45.1096C22.5677 52.5379 26.049 54.6592 26.049 54.6592C26.049 54.6592 38.9774 68.5221 49.1309 53.3223C59.2844 38.1225 66.2919 25.8007 66.2919 25.8007C66.2919 25.8007 77.7201 10.3493 89.7772 25.8007C101.834 41.252 106.605 46.1386 106.605 46.1386C106.605 46.1386 115.236 56.0599 128.601 47.8666C141.966 39.6734 144.216 37.7522 144.216 37.7522C144.216 37.7522 156.781 31.4802 167.261 39.6734C177.741 47.8666 185.92 50.6986 185.92 50.6986C185.92 50.6986 198.094 55.1275 203.94 42.6847C209.786 30.2419 221.923 -1.41389 232.234 0.874138C232.195 12.7389 232.218 49.5787 232.23 64.109C232.232 67.4249 229.545 70.0887 226.229 70.0887H6.12451C2.8108 70.0887 0.124512 67.4025 0.124512 64.0887V35.4607Z" fill="url(#paint0_linear_659_8944)"/>
<defs>
<linearGradient id="paint0_linear_659_8944" x1="0.124512" y1="0.756348" x2="0.124512" y2="70.0887" gradientUnits="userSpaceOnUse">
<stop stop-color="#00CB65"/>
<stop offset="1" stop-color="white" stop-opacity="0.01"/>
</linearGradient>
</defs>
</svg>

`,
        color: "#28C76F1F ",
    },
];

export default cards;
