import React from "react";

import { motion } from "framer-motion";

const Card = ({ number, icon, title, line, fill, color }) => {
    return (
        <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.4 }}
            className="flex flex-col max-sm:mx-auto rounded-[6px] max-w-[230px] bg-white border-[6] w-full relative overflow-hidden"
        >
            <div className="py-2 px-6">
                <div
                    style={{ backgroundColor: color }}
                    className="p-2 w-fit rounded-full flex justify-center items-center"
                >
                    <span dangerouslySetInnerHTML={{ __html: icon }}></span>
                </div>
            </div>
            <p className="text-[24px] font-semibold text-paragraph-light px-6 py-1">
                {number}
            </p>
            <p className="text-[14px] font-almarai text-paragraph-light font-normal px-6 py-1">
                {title}
            </p>
            <div className="relative w-full h-20 bg-white   ">
                <span
                    className="absolute inset-0 z-10 w-full"
                    style={{
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                    }}
                    dangerouslySetInnerHTML={{ __html: line }}
                ></span>
                <span
                    className="absolute inset-0 w-full"
                    style={{
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                    }}
                    dangerouslySetInnerHTML={{ __html: fill }}
                ></span>
            </div>
        </motion.div>
    );
};

export default Card;
