import React from "react";
import { useNavigate } from "react-router";
import { format } from "date-fns";

const NotificationImportCard = ({ e, title, date, refrenceId, setOpen }) => {
    const router = useNavigate();
    return (
        <div
            onClick={() => {
                if (e.type === "post") router(`/posts/${refrenceId}`);
                else router(`/imported/${refrenceId}`);
                setOpen(false);
            }}
            className="flex gap-4 items-center border-b border-input py-2  p-4 hover:bg-slate-50 transition-all rounded-md cursor-pointer"
        >
            <div>
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.0656 6.56104C18.0625 6.18217 16.9375 6 15.771 6C13.8753 6 12.0889 6.48116 10.7577 7.50587C9.40327 8.54844 8.57808 10.1123 8.57808 12.1191C8.57808 14.0485 7.8689 16.2708 7.09959 18.0981C6.27161 20.0647 7.62393 22.5336 9.89977 22.5336H11.7996V22.8574C11.7996 23.9746 12.2642 24.8673 13.0356 25.4612C13.7839 26.0371 14.7649 26.2919 15.771 26.2919C16.7772 26.2919 17.7582 26.0371 18.5065 25.4612C19.2779 24.8673 19.7425 23.9746 19.7425 22.8574V22.5336H21.6423C23.9181 22.5336 25.2705 20.0647 24.4425 18.0981C24.3334 17.8391 24.2256 17.5721 24.1208 17.2992C23.64 17.4225 23.136 17.488 22.6167 17.488C22.6075 17.488 22.5984 17.488 22.5892 17.4879C22.7423 17.904 22.9014 18.3035 23.06 18.6801C23.5276 19.7907 22.7052 21.0336 21.6423 21.0336H18.9925H12.5496H9.89977C8.83689 21.0336 8.0145 19.7907 8.48206 18.6801C9.27304 16.8014 10.0781 14.3544 10.0781 12.1191C10.0781 10.5676 10.6952 9.4469 11.6727 8.6945C12.6733 7.92423 14.1084 7.5 15.771 7.5C16.5131 7.5 17.2098 7.58451 17.8429 7.74657C18.1925 7.2963 18.6044 6.89681 19.0656 6.56104ZM13.2996 22.5336H18.2425V22.8574C18.2425 23.5194 17.986 23.9689 17.5915 24.2725C17.1738 24.5941 16.5441 24.7919 15.771 24.7919C14.998 24.7919 14.3683 24.5941 13.9506 24.2725C13.5561 23.9689 13.2996 23.5193 13.2996 22.8574V22.5336Z"
                        fill="#0189C7"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.8589 6C17.7545 6.00006 19.5408 6.48122 20.8719 7.50587C22.2264 8.54844 23.0516 10.1123 23.0516 12.1191C23.0516 14.0485 23.7607 16.2708 24.53 18.0981C25.358 20.0647 24.0057 22.5336 21.7299 22.5336H19.8301V22.8574C19.8301 23.9746 19.3655 24.8673 18.594 25.4612C17.8458 26.0371 16.8649 26.2919 15.8589 26.2919V24.7919C16.6318 24.7919 17.2614 24.594 17.679 24.2725C18.0735 23.9689 18.3301 23.5193 18.3301 22.8574V22.5336H15.8589V21.0336H19.0801H21.7299C22.7928 21.0336 23.6151 19.7907 23.1476 18.6801C22.3566 16.8014 21.5516 14.3544 21.5516 12.1191C21.5516 10.5676 20.9344 9.4469 19.957 8.6945C18.9564 7.92428 17.5214 7.50006 15.8589 7.5V6Z"
                        fill="#0189C7"
                    />
                    <circle
                        cx="16"
                        cy="16"
                        r="16"
                        fill="#2176FF"
                        fill-opacity="0.2"
                    />
                </svg>
            </div>
            <div className="text-black/60 text-sm">
                <p className="text-black">{title}</p>
                <p className="text-xs mt-1">{format(date, "dd-MM-yyyy")}</p>
            </div>
        </div>
    );
};

export default NotificationImportCard;
