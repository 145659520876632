import React, { useState } from "react";
import EditService from "./editService";
import { baseURL } from "../../hook/request";

import { motion } from "framer-motion";
const Item = ({
    imgUrl,
    dateCreated,
    title,
    serviceId,
    refetch,
    transition,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <EditService
                refetch={refetch}
                serviceId={serviceId}
                open={open}
                setOpen={setOpen}
            />

            <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: transition / 10 + 0.3 }}
                className=" bg-white rounded-[10px]   h-[287px] "
            >
                <div className="h-[165px]  rounded-[10px] overflow-hidden ">
                    {" "}
                    <img
                        src={`${baseURL}${imgUrl}`}
                        className="w-full object-cover  h-full"
                        alt=""
                    />{" "}
                </div>
                <div className=" p-[20px] flex flex-col gap-[10px]  ">
                    <p className="  font-semibold texet-[14px]  text-paragraph-dark font-almarai">
                        {title}
                    </p>
                    <p className="  font-normal  whitespace-nowrap  text-[12px] text-paragraph-dark font-almarai">
                        {" "}
                        تاريخ الاضافة {dateCreated}
                    </p>
                </div>
                <button
                    onClick={() => setOpen(true)}
                    className=" font-almarai  bg-main rounded-[10px] border border-1 whitespace-nowrap text-white w-full h-[41px]   text-[14px]  font-medium    py-[10px] px-[20px]"
                >
                    تعديل
                </button>
            </motion.div>
        </>
    );
};

export default Item;
