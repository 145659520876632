import { useMutation, useQuery } from "react-query";
import useWebsiteStore from "../store/store";
import { request } from "./request";
const deleteServices = async (id, token) => {
  try {
    const response = await request.delete(`/api/c_panel/delet_service`, {
      params: { id },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) { 
    throw error;
  }
};
const useDeleteServices = ({ onSuccess, onError, id }) => {
  const token = useWebsiteStore((state) => state.token);
  return useMutation(["delete-services", id], () => deleteServices(id, token), {
    onSuccess,
    onError,
  });
};

const getAllServices = async (payload, token) => {
  const response = await request.post(
    "/api/c_panel/get_all_services",
    payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const useGetAllServices = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-all-services", payload], () =>
    getAllServices(payload, token)
  );
};

const getServiceById = async (payload, token) => {
  try {
    const response = await request.get("/api/c_panel/get_service", {
      params: payload,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) { 
    throw error;
  }
};

const useGetServiceById = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-service", payload], () =>
    getServiceById(payload, token)
  );
};

export const updateService = async (serviceId, data, token) => {  
  try {
    const response = await request.put(`/api/c_panel/update_service`, data, {
      params: { serviceId },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) { 
    throw error;
  }
};

const addServices = async (data, token) => {
  const response = await request.post("/api/c_panel/add_service", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useAddServices = ({ onSuccess, onError, payload }) => {
  const token = useWebsiteStore((state) => state.token);

  return useMutation(
    ["add-services", payload],
    () => addServices(payload, token),
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useGetAllServices,
  useAddServices,
  useDeleteServices,
  useGetServiceById,
};
