import React from "react";

const InputSearch = ({ placeholder, ...props }) => {
  return (
    <div className=" relative rounded-[10px] overflow-hidden   sm:w-[290px] md:w-[450px] w-full  h-[50px] flex items-center justify-center  ">
      <div className="  absolute top-0 left-0 bg-main-90 w-[200%] h-[200%]  z-0"></div>
      <div className=" calc rounded-[10px] border border-1 border-input  z-10   bg-white flex flex-row items-center  justify-between py-[10px] px-[15px]">
        <div className=" flex flex-row  items-center">
          <img
            src="/assets/images/search.svg"
            alt="search"
            className=" w-[18px] h-[18px]   "
          />
          <input
            type="text"
            placeholder={placeholder}
            {...props}
            className="  reounded-[10px] px-[5px] py-[10px]  sm:py-[10px] sm:px-[15px] h-[30px] xsm:w-fit w-[110px]  focus:outline-none placeholder:text-xs  focus:placeholder:opacity-0 focus:transition-all outline-none transition-all  placeholder:transition-all"
          />{" "}
        </div>
        <img
          src="/assets/images/delete.svg"
          alt="selete"
          className=" w-[16px] h-[16px] cursor-pointer hidden sm:block  "
        />
      </div>
    </div>
  );
};

export default InputSearch;
