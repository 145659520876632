import { useMutation, useQuery } from "react-query";
import useWebsiteStore from "../store/store";
import { request } from "./request";

const getAllGifts = async (payload, token) => {
    const response = await request.post(
      "/api/c_panel/gift/get_all",
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  };
  

  
const useGetAllGifts = (payload) => {
    const token = useWebsiteStore((state) => state.token);
    return useQuery(["get-all-gifts", payload], () =>
        getAllGifts(payload, token)
    );
  };
  

  const addGift = async (data, token) => {
    const response = await request.post("/api/c_panel/gift/add", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  };
  
  const useAddGift = ({ onSuccess, onError, payload }) => {
    const token = useWebsiteStore((state) => state.token);
    return useMutation(
      ["add-gift", payload],
      () => addGift(payload, token),
      {
        onSuccess,
        onError,
      }
    );
  };

  export const updateGift = async (id, data, token) => { 
    try {
      const response = await request.put(`/api/c_panel/gift/update`, data, {
        params: { id },
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) { 
      throw error;
    }
  };
  

  const getGiftById = async (payload, token) => {
    try {
      const response = await request.get("/api/c_panel/gift/get", {
        params: payload,
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) { 
      throw error;
    }
  };
  
  const useGetGiftById = (payload) => {
    const token = useWebsiteStore((state) => state.token);
    return useQuery(["get-gift-by-id", payload], () =>
        getGiftById(payload, token)
    );
  };
  



  const deleteGift = async (id, token) => {
    try {
      const response = await request.delete(`/api/c_panel/gift/delete`, {
        params: { id },
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) { 
      throw error;
    }
  };
  const useDeleteGift = ({ onSuccess, onError, id }) => {
    const token = useWebsiteStore((state) => state.token);
    return useMutation(["delete-gift", id], () => deleteGift(id, token), {
      onSuccess,
      onError,
    });
  };
  

  export { 
    useGetAllGifts,
    useAddGift,
    useGetGiftById,
    useDeleteGift
  }