import { CircularProgress, Container } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { verifyOtp } from "../hook/forgetpassword";
import { useNavigate } from "react-router";
import useWebsiteStore from "../store/store";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

let currentOTPIndex = 0;
const ForgetPassword = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const router = useNavigate();
  const setOtpStore = useWebsiteStore((state) => state.setOtp);

  const [searchParams] = useSearchParams();
  const nameId = searchParams.get("nameId");

  const inputRef = useRef(null);

  const token = useWebsiteStore((state) => state.token);

  const handleForgetPassword = async () => {
    try {
      setLoading(true);
      await verifyOtp(
        {
          nameId,
          otp: otp.join("").split(",").join(),
        },
        token
      );
      setOtpStore(otp.join("").split(",").join());
      router(`reset-password?nameId=${nameId}`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleOnChange = (event) => {
    const { value } = event.target;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);

    setOtp(newOTP);
  };

  const handleOnKeyDown = (event, index) => {
    currentOTPIndex = index;
    if (event.key === "Backspace") setActiveOTPIndex(currentOTPIndex - 1);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  return (
    <div className=" relative w-full h-[100vh] flex justify-center items-center ">
      <img
        src="/assets/images/bg-layout.svg"
        alt="bg-layout"
        className=" w-[100%] h-[100%] absolute left-0 top-0  object-cover  z-0 "
      />
      <Container>
        <div className="    relative z-10  flex flex-col  gap-10  ">
          <div className=" flex flex-col gap-10 ">
            {" "}
            <h1 className="text-[40px]  font-normal  text-black  ">
              {" "}
              وصلك بريد
            </h1>
            <p className=" font-normal text-[16px]  text-black xsm:w-[439px] w-full">
              لتفعيل حسابك ادخل الكود
            </p>
          </div>
          {/* login box */}
          <div className=" flex flex-col gap-[24px]  h-auto xsm:w-[450px] w-[96%] p-[32px]  xsm:min-h-[467px] bg-white rounded-[6px] shadow-custom  justify-center items-center   m-auto">
            <h1 className=" text-[26px]  font-bold  w-fit mx-auto font-baloo">
              {" "}
              Logo{" "}
            </h1>
            <div className=" flex flex-col gap-[6px] w-full">
              <p className=" w-full text-[24px]   font-public-sans font-bold text-paragraph">
                التحقق بخطوتين💬
              </p>
              <p className=" w-full text-[17px]  font-normal font-public-sans text-paragraph">
                لقد أرسلنا رمز التحقق إلى هاتفك المحمول. أدخل الرمز من الجوال في
                الحقل أدناه.
              </p>
              <p>99999 </p>
            </div>
            <div className=" w-full  flex flex-col gap-[10px] ">
              <p className="  text-[15px]  font-semibold w-full  text-paragraph">
                اكتب رمز الحماية المكون من 6 أرقام
              </p>
              <div className="flex flex-row justify-evenly" dir="ltr">
                {otp.map((_, index) => {
                  return (
                    <React.Fragment key={index}>
                      <input
                        ref={activeOTPIndex === index ? inputRef : null}
                        type="number"
                        className={
                          "w-12 h-12 border-2 rounded bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition"
                        }
                        onChange={handleOnChange}
                        onKeyDown={(event) => handleOnKeyDown(event, index)}
                        value={otp[index]}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>

            <div className=" w-full flex flex-col gap-[16px] ">
              <button
                onClick={() => handleForgetPassword()}
                className="  bg-main rounded-[6px] text-white w-full text-[15px]    py-[10px] px-[20px]"
              >
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "تحقق من حسابي"
                )}
              </button>
              <div className=" font-normal text-[15px]  text-main  w-fit mx-auto  flex flex-row  items-center  gap-[5px]  justify-center text-center">
                <p>
                  ألم تحصل على الرمز؟{" "}
                  <span className=" text-error cursor-pointer ">
                    إعادة إرسال
                  </span>
                </p>
              </div>
            </div>
          </div>{" "}
        </div>
      </Container>
    </div>
  );
};

export default ForgetPassword;
