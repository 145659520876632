export const postCards = [
    {
        title: "عدد المشاركات",
        number: "3.4k",
        icon: `<svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.61975 13.9994C8.69312 14.0426 8.77527 14.0641 8.85742 14.0641C8.93958 14.0641 9.02173 14.0426 9.09509 13.9994C12.307 12.1103 17.0309 8.11267 16.8525 4.33276C16.7422 1.9939 14.9368 0.0180663 12.6406 0.000121923C10.9312 -0.0129396 9.6499 1.02466 8.85742 2.53198C8.06116 1.01758 6.77808 -0.0126955 5.0741 0.000121923C2.77857 0.0180663 0.97266 1.99329 0.862309 4.33276C0.684208 8.10888 5.41333 12.1136 8.61975 13.9994ZM1.79883 4.37683C1.88916 2.46204 3.33106 0.951415 5.08142 0.937744C5.08997 0.937622 5.09839 0.937622 5.10681 0.937622C6.88648 0.937622 8.04187 2.56824 8.40552 3.87536C8.46167 4.07849 8.64661 4.21924 8.85742 4.21924C9.0686 4.21924 9.25366 4.07812 9.30957 3.87451C9.31763 3.84497 10.1436 0.937622 12.6088 0.937622C12.6169 0.937622 12.6251 0.937622 12.6334 0.937744C14.3838 0.951415 15.8257 2.46216 15.9159 4.37695C16.1 8.27795 10.1334 12.2432 8.8573 13.0452C7.58057 12.2434 1.61475 8.28173 1.79883 4.37683Z" fill="#2B3240"/>
</svg>

`,
        color: "#FF9F431F",
    },
    {
        title: "عدد التعليقات ",
        number: "3.4k",
        icon: `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.85742 21V8C4.85742 6.34315 6.20057 5 7.85742 5H17.8574C19.5143 5 20.8574 6.34315 20.8574 8V14C20.8574 15.6569 19.5143 17 17.8574 17H8.85742L4.85742 21" stroke="#28C76F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.8574 11V11.01" stroke="#28C76F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.85742 11V11.01" stroke="#28C76F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.8574 11V11.01" stroke="#28C76F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

`,
        color: "#28C76F1F ",
    },
    {
        title: "عدد المشاركات",
        number: "3.4k",
        icon: `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="3.85742" y="7" width="18" height="13" rx="2" stroke="#EA5455" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.85742 7V5C8.85742 3.89543 9.75285 3 10.8574 3H14.8574C15.962 3 16.8574 3.89543 16.8574 5V7" stroke="#EA5455" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.8574 12V12.01" stroke="#EA5455" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.85742 13C9.51837 15.8526 16.1965 15.8526 21.8574 13" stroke="#EA5455" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
        color: "#FF9F431F",
    },
];
