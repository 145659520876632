import { Button, CircularProgress, Container } from '@mui/material';
import React, { useEffect, useState } from 'react'
// import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import { updateGift, useDeleteGift, useGetGiftById } from '../../hook/gift';
import BaseInput from '../base-input';
import BaseTextarea from '../base-textarea';
import { toast } from 'react-toastify';
import InputImage from '../input-image';
import useWebsiteStore from '../../store/store';

const EditGift = ({ setOpen, giftId, refetch, open }) => {
 
const [mounted, setMounted] = useState(true);

    const { data: info, isLoading } = useGetGiftById({ id: giftId }); 
    const [data, setData] = useState({
        name: "",
        description: "",
        price:0
    });
    const [imageUrl, setImageUrl] = useState(info?.data.imageUrl);
    const token = useWebsiteStore((state) => state.token);
   
    useEffect(() => {
        setData({ ...data, profileImageUrl: imageUrl });
    }, [imageUrl]);

    const update = async () => {
        setLoading(true);
        try {
            const payload = {
                imageUrl: imageUrl,
                name: data.name,
                price: data.price,
                description: data.description,
            };
            await updateGift(giftId, payload, token);
            refetch();
            setLoading(false);
            toast.success("Gift updated successfully!");
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        } catch (error) {
            setLoading(false);
            toast.error("Error updating Gift"); 
        }
    };

    useEffect(() => {
        if (!isLoading && info && mounted) {
            setData({
                description: info?.data?.description,
                name: info?.data?.name,
                price: info?.data?.price,
            });
            setImageUrl(info?.data?.imageUrl);
            setMounted(false);
        }
    }, [info, isLoading, mounted]);


    const [loading, setLoading] = useState(false);
    const { mutate } = useDeleteGift({
        onSuccess: () => {
            refetch();
            setLoading(false);
            toast.success("Gift deleted successfully!");
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        },
        onError: (error) => {
            setLoading(false);
            toast.error("Error deleting Gift");
        },
        id: giftId,
    });
    const handleDeleteGift = () => {
        setLoading(true);
        mutate();
    };


  return (
    <AnimatePresence>
    {open && (
        <motion.div className="fixed inset-0 flex items-center justify-center z-20 bg-[#00000033] bg-opacity-50">
            <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="bg-white shadow-lg z-40 relative rounded-[5px]  sm:w-[711px] w-[95%]"
            >
                <div className="flex flex-row justify-between items-center bg-[#D3EDB4] rounded-[5px] py-[15px] pr-[35px] pl-[18px] ">
                    <p className="font-light text-[16px] text-black">
                        تعديل الهدية
                    </p>
                    <img
                        src="/assets/images/close.svg"
                        alt="close"
                        onClick={() => {
                            setOpen(false);
                        }}
                        className="cursor-pointer"
                    />
                </div>
                {isLoading ? (
                    <div className="w-full flex justify-center items-center h-[50vh]">
                        {" "}
                        <CircularProgress />
                    </div>
                ) : (
                    <Container>
                        <div className=" flex sm:flex-row flex-col gap-2    justify-between p-2 py-[3rem]  ">
                            <div>
                                <div className="md:w-[292px] w-full">
                                    <BaseInput
                                        label={"اسم الهدية "}
                                        value={data.name}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="md:w-[292px] w-full">
                                    <BaseInput
                                        label={"سعر الهدية "}
                                        value={data.price}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                price: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className=" md:w-[292px] w-full">
                                    <BaseTextarea
                                        label={"وصف الهدية"}
                                        value={data.description}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                description:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className=" w-[329px]  h-[247px]   flex flex-col gap-2 ">
                                {info && (
                                    <InputImage
                                        type="GiftImage"
                                        ImageUrl={imageUrl}
                                        setImageUrl={setImageUrl}
                                    />
                                )}
                            </div>
                        </div>
                        <div className=" flex flex-row  justify-end gap-[10px] p-[3rem] ">
                            {" "}
                            <Button
                                variant="contained"
                                onClick={update}
                                disabled={loading}
                            >
                                تعديل
                            </Button>
                            <Button
                                onClick={() => {
                                    setOpen(false);
                                }}
                                variant="outlined"
                            >
                                تراجع
                            </Button>
                            <Button
                                onClick={handleDeleteGift}
                                color="error"
                                variant="outlined"
                                disabled={loading}
                            >
                                حذف
                            </Button>
                        </div>
                    </Container>
                )}
            </motion.div>
        </motion.div>
    )}
</AnimatePresence>
  )
}

export default EditGift