import { useMutation } from "react-query";
import useWebsiteStore from "../store/store";
import { request } from "./request";

const addFile = async (file, type, token) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("type", type);
    const response = await request.post("/api/c_panel/add_file", formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    });
    return response.data;
};
// useAddFile hook
const useAddFile = ({ onSuccess, onError, type }) => {
    const token = useWebsiteStore((state) => state.token);

    return useMutation(
        ["add-file", type],
        (file) => addFile(file, type, token),
        {
            onSuccess,
            onError,
        }
    );
};

export { useAddFile };
