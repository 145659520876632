import React from "react";
import { Outlet } from "react-router";
import UserInfo from "./user-info";
import { NavLink } from "react-router-dom";

const UsersDetails = () => {
    return (
        <>
            <div className=" py-[10px] md:px-[30px] px-[10px]  bg-[#f8f8f8f8]">
                <UserInfo />
            </div>
            <div className=" flex justify-center items-center flex-col gap-5 py-6">
                <ul className="flex flex-row md:gap-3 gap-1">
                    <li>
                        <NavLink
                            to="pointrecords"
                            className={({ isActive }) =>
                                isActive
                                    ? "text-[16px] font-bold  transition-all  whitespace-nowrap  border-b-2  border-b-error p-[5px] md:m-[10px]"
                                    : "text-[16px] text-paragraph-light   whitespace-nowrap p-[5px] md:m-[10px]"
                            }
                        >
                            سجل النقاط
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="friends"
                            className={({ isActive }) =>
                                isActive
                                    ? "text-[16px] font-bold  transition-all   border-b-2  border-b-error p-[5px] md:m-[10px]"
                                    : "text-[16px] text-paragraph-light p-[5px] md:m-[10px]"
                            }
                        >
                            الاصدقاء
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="chat"
                            className={({ isActive }) =>
                                isActive
                                    ? "text-[16px] font-bold  transition-all   border-b-2  border-b-error p-[5px] md:m-[10px]"
                                    : "text-[16px] text-paragraph-light p-[5px] md:m-[10px]"
                            }
                        >
                            المحادثة
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink
                            to="posts"
                            className={({ isActive }) =>
                                isActive
                                    ? "text-[16px] font-bold  transition-all   border-b-2  border-b-error p-[5px] md:m-[10px]"
                                    : "text-[16px] text-paragraph-light p-[5px] md:m-[10px]"
                            }
                        >
                            المنشورات
                        </NavLink>
                    </li> */}
                </ul>
                <Outlet />
            </div>
        </>
    );
};

export default UsersDetails;
