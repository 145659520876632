import { useState } from "react";
import Heading from "../components/heading";
import InputSearch from "../components/inputSearch";
import Select from "../components/select";
import { useNavigate } from "react-router";
import InputDate from "../components/inputDate";
import { useGetAllPost } from "../hook/post";
import { useDebounce } from "use-debounce";
import PaginationCom from "../components/pagination";
import { useGetCategory } from "../hook/settings";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const Posts = () => {
    const [state, setState] = useState("All");
    const [categoryId, setCategoryId] = useState("");
    const router = useNavigate();
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [search, setSearch] = useState("");
    const [PageNumber, setPageNumber] = useState(1);

    const [key] = useDebounce(search, 500);
    const { data: category } = useGetCategory({
        IsPaginat: false,
    });
    const handleFromDateChange = (e) => {
        setFrom(e.target.value);
    };

    const handleToDateChange = (e) => {
        setTo(e.target.value);
    };

    const { data, isLoading } = useGetAllPost({
        categoryId,
        state,
        startDate: from ? new Date(from)?.toISOString() : "",
        endDate: to ? new Date(to)?.toISOString() : "",
        key,
        IsPaginat: true,
        PageNumber,
        PageSize: 10,
    });
    return (
        <div className=" py-[10px] md:px-[30px] px-[10px]  bg-[#f8f8f8f8]">
            <div className=" flex sm:flex-row flex-col justify-between xsm:items-center w-full gap-[15px] ">
                <Heading title={"المنشورات"} subtitle={"المنشورات"} />
                <div className=" flex flex-row justify-between items-center  gap-[10px] flex-wrap  w-full sm:w-auto">
                    <InputSearch
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="ابحث عن مستخدم محد لعرض منشوراته"
                    />
                </div>
            </div>
            <div className=" flex gap-4 w-full justify-between my-4 max-sm:flex-col max-sm:justify-center">
                <div className="flex gap-4 items-center justify-between max-sm:flex-col max-sm:justify-center">
                    <Select
                        selected={categoryId}
                        setSelected={setCategoryId}
                        data={category?.data.map((e) => ({
                            name: e.name,
                            id: e.id,
                        }))}
                    />
                    <Select
                        selected={state}
                        setSelected={setState}
                        data={[
                            { name: " الكل", id: "All" },
                            { name: " قيد المعالجة", id: "InProcess" },
                            { name: " مقبول", id: "Accept" },
                            { name: " مرفوض", id: "Reject" },
                            { name: " مثبت", id: "Pin" },
                            { name: " مقبول غير مثبت", id: "AcceptNotPin" },
                            { name: " غير مقبول", id: "NotAccept" },
                        ]}
                    />
                </div>
                <div className="flex gap-4 items-center ">
                    <p className="font-semibold text-sm">تاريخ النشر</p>
                    <div className="flex items-center gap-2 max-sm:flex-col max-sm:justify-center">
                        <p className="flex items-center gap-1 text-xs ">
                            من
                            <InputDate
                                max={to}
                                value={from}
                                onChange={handleFromDateChange}
                            />
                        </p>
                        <p className="flex items-center gap-1 text-xs ">
                            الى{" "}
                            <InputDate
                                min={from}
                                value={to}
                                onChange={handleToDateChange}
                            />
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-4 ">
                <div className="w-full mt-6 overflow-auto flex  ">
                    {isLoading ? (
                        <div className="w-full flex justify-center items-center h-[50vh]">
                            {" "}
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <table className="w-full text-center min-w-[900px] ">
                                <thead className="text-[#404D61] text-sm">
                                    <tr>
                                        <th>تابع للمستخدم</th>
                                        <th>التصنيف</th>
                                        <th>عدد التفاعلات</th>
                                        <th>عدد التعليقات</th>
                                        <th>عدد المشاركات</th>
                                        <th> تاريخ النشر</th>
                                        <th>حالة المنشور</th>
                                        <th> تفاصيل المنشورات</th>
                                    </tr>
                                    <tr className="h-[10px]" />
                                </thead>
                                <tbody>
                                    <tr className="h-[10px]" />
                                    {data?.data?.map((e) => (
                                        <>
                                            <tr
                                                key={e.id}
                                                onClick={() =>
                                                    router(`/posts/${e.id}`)
                                                }
                                                className="bg-white cursor-pointer h-[45px] text-[#6E6B7B] text-sxm"
                                            >
                                                <td> {e.userName}</td>
                                                <td>{e.nameCategory} </td>
                                                <td>{e.countComments}</td>
                                                <td>{e.countInteractions}</td>
                                                <td>{e.countShares}</td>
                                                <td>{e.dateCreated}</td>
                                                <td
                                                    style={{
                                                        color:
                                                            `${e.state}` ===
                                                            "Accept"
                                                                ? "#28C76F"
                                                                : "#007FFF",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            backgroundColor:
                                                                `${e.state}` ===
                                                                "Accept"
                                                                    ? "#28C76F1F"
                                                                    : "#007FFF1F",
                                                        }}
                                                        className=" py-1 px-4 rounded-full text-sm "
                                                    >
                                                        {e.state}
                                                    </span>
                                                </td>
                                                <td>
                                                    <svg
                                                        className="mx-auto "
                                                        width="3"
                                                        height="13"
                                                        viewBox="0 0 3 13"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <ellipse
                                                            cx="1.40789"
                                                            cy="11.5"
                                                            rx="1.5"
                                                            ry="1.40789"
                                                            transform="rotate(-90 1.40789 11.5)"
                                                            fill="#0189C7"
                                                        />
                                                        <ellipse
                                                            cx="1.40789"
                                                            cy="6.5"
                                                            rx="1.5"
                                                            ry="1.40789"
                                                            transform="rotate(-90 1.40789 6.5)"
                                                            fill="#0189C7"
                                                        />
                                                        <ellipse
                                                            cx="1.40789"
                                                            cy="1.5"
                                                            rx="1.5"
                                                            ry="1.40789"
                                                            transform="rotate(-90 1.40789 1.5)"
                                                            fill="#0189C7"
                                                        />
                                                    </svg>
                                                </td>
                                            </tr>
                                            <tr className="h-[10px]" />
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </div>
            <div className="ltr mx-auto w-fit mt-4">
                <PaginationCom
                    count={data?.totalPage}
                    page={PageNumber}
                    setPage={setPageNumber}
                    size="small"
                    color="primary"
                />
            </div>
        </div>
    );
};

export default Posts;
