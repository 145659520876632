import { Container } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";

const ModalAdd = ({ children, onClose, top, icon }) => {
    return (
        <motion.div className="fixed inset-0 flex items-center  justify-center z-20 bg-[#00000033] bg-opacity-50 ">
            <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="bg-white shadow-lg z-40 relative rounded-[5px]  sm:w-[711px] w-[95%]"
            >
                <div className="flex flex-row justify-between items-center bg-[#D3EDB4] rounded-[5px] py-[15px] pr-[35px] pl-[18px] ">
                    <p className="font-light text-[16px] text-black flex flex-row justify-center gap-2 items-center">
                        <span dangerouslySetInnerHTML={{ __html: icon }}></span>
                        <span> {top} </span>
                    </p>
                    <img
                        src="/assets/images/close.svg"
                        alt="close"
                        onClick={() => onClose?.()}
                        className="cursor-pointer"
                    />
                </div>
                <Container
                    sx={{
                        paddingBottom: "20px",
                    }}
                >
                    {children}
                </Container>
            </motion.div>
        </motion.div>
    );
};

export default ModalAdd;
