import React from "react";
import { baseURL } from "../../hook/request";

const ChannelContact = ({ name, imageUrl }) => {
    return (
        <div
            className={
                "felx flex-row cursor-pointer  gap-7 w-full transition-all"
            }
        >
            <div className="  p-4  w-full border  h-full flex flex-row   justify-end gap-3 items-center ">
                {" "}
                <p className=" font-semibold text-[14px] "> {name} </p>
                <div className=" object-cover overflow-hidden  w-[50px] h-[46px] items-center   rounded-full">
                    <img
                        src={`${baseURL}${imageUrl}`}
                        className=" object-cover  h-[50px] items-center     "
                        alt=""
                    />{" "}
                </div>
            </div>
        </div>
    );
};

export default ChannelContact;
