import React, { useState } from "react";
import Heading from "../heading";
import { Button, CircularProgress, Pagination } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useGetNorificationById } from "../../hook/notification";
import { useEffect } from "react";
// notification svg
const svg = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 6C13.5 4.80653 13.0259 3.66193 12.182 2.81802C11.3381 1.97411 10.1935 1.5 9 1.5C7.80653 1.5 6.66193 1.97411 5.81802 2.81802C4.97411 3.66193 4.5 4.80653 4.5 6C4.5 11.25 2.25 12.75 2.25 12.75H15.75C15.75 12.75 13.5 11.25 13.5 6Z" stroke="#0189C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.2976 15.75C10.1658 15.9773 9.97652 16.166 9.74881 16.2971C9.52109 16.4283 9.26292 16.4973 9.00014 16.4973C8.73735 16.4973 8.47918 16.4283 8.25147 16.2971C8.02375 16.166 7.83449 15.9773 7.70264 15.75" stroke="#0189C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const NotificationDetails = () => {
    const router = useNavigate();
    const [mounted, setMounted] = useState(true);
    const { id } = useParams();
    const { data: info, isLoading } = useGetNorificationById(id);
    const [data, setData] = useState({
        title: info?.data?.title,
        body: info?.data?.body,
        date: info?.data?.date,
        typeSend: info?.data?.typeSend,
    });
    useEffect(() => {
        if (!isLoading && info && mounted) {
            setData({
                title: info?.data?.title,
                body: info?.data?.body,
                date: info?.data?.date,
                typeSend: info?.data?.typeSend,
            });
            setMounted(false);
        }
    }, [data, isLoading, mounted]);
    return (
        <div className=" py-[10px] md:px-[30px] sm:px-[10px]  bg-[#f8f8f8f8]   h-full">
            <div className=" flex max-sm:flex-col justify-between  gap-2  pb-[1rem]">
                <Heading
                    title={"الاشعارات"}
                    subtitle={"تفاصيل الاشعار"}
                    icon={svg}
                />
                <span onClick={() => router("/notifications")}>
                    {" "}
                    <Button variant="outlined">تراجع</Button>
                </span>
            </div>
            {isLoading ? (
                <div className="w-full flex justify-center items-center h-[50vh]">
                    {" "}
                    <CircularProgress />
                </div>
            ) : (
                <div className="  flex flex-col gap-20 items-start py-[50px]  h-full w-full! ">
                    <div className=" flex justify-start items-start w-full  bg-white sm:p-[1.5rem]   rounded-[6px] ">
                        <div className=" w-full  p-[1rem]">
                            <div className=" w-full! border-b py-[1.5rem] border-b-[1]  border-[#EBE9F1] flex flex-row justify-between  items-center ">
                                <h2 className=" text-[15px]  text-paragraph  font-normal  font-almarai w-full ">
                                    {data?.title}
                                </h2>
                                <div className=" flex flex-row gap-2 items-center justify-center">
                                    {" "}
                                    <p className="   text-[#B9B9C3] text-[14px] font-normal whitespace-nowrap">
                                        {data?.date}
                                    </p>
                                    <span className=" cursor-pointer">
                                        <svg
                                            width="15"
                                            height="14"
                                            viewBox="0 0 15 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clip-path="url(#clip0_659_10827)">
                                                <path
                                                    d="M1.92529 3.5H3.09196H12.4253"
                                                    stroke="#5E5873"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M4.84204 3.5V2.33333C4.84204 2.02391 4.96496 1.72717 5.18375 1.50837C5.40254 1.28958 5.69929 1.16667 6.00871 1.16667H8.34204C8.65146 1.16667 8.94821 1.28958 9.167 1.50837C9.38579 1.72717 9.50871 2.02391 9.50871 2.33333V3.5M11.2587 3.5V11.6667C11.2587 11.9761 11.1358 12.2728 10.917 12.4916C10.6982 12.7104 10.4015 12.8333 10.092 12.8333H4.25871C3.94929 12.8333 3.65254 12.7104 3.43375 12.4916C3.21496 12.2728 3.09204 11.9761 3.09204 11.6667V3.5H11.2587Z"
                                                    stroke="#5E5873"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M8.34204 6.41667V9.91667"
                                                    stroke="#5E5873"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M6.00854 6.41667V9.91667"
                                                    stroke="#5E5873"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_659_10827">
                                                    <rect
                                                        width="14"
                                                        height="14"
                                                        fill="white"
                                                        transform="translate(0.175293)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className=" sm:pb-[3rem]  py-[1rem] w-full">
                                <p className=" font-almarai font-normal text-[14px] text-paragraph-light leading-7">
                                    {data?.body}
                                </p>
                            </div>
                            <p
                                dir="ltr"
                                className=" leading-7 text-[15px] font-almarai  text-paragraph p-4 font-bold"
                            >
                                المرسل اليه: {data?.typeSend}
                            </p>
                        </div>
                    </div>{" "}
                    <div className="ltr mx-auto w-fit mt-4">
                        <Pagination count={10} size="small" color="primary" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationDetails;
