import React, { useState } from "react";
import PaginationCom from "../pagination";
import { useNavigate, useParams } from "react-router";
import { useGetAllFriendsForUser } from "../../hook/users";
import { baseURL } from "../../hook/request";
import { CircularProgress } from "@mui/material";

const Friends = () => {
    const { id } = useParams();
    const router = useNavigate();
    const [PageNumber, setPageNumber] = useState(1);
    const { data, isLoading } = useGetAllFriendsForUser(id, {
        IsPaginat: true,
        PageNumber: 1,
        PageSize: 10,
        key: "",
    });

    return (
        <>
            {isLoading ? (
                <div className="w-full flex justify-center items-center h-[50vh]">
                    {" "}
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {" "}
                    <div className="w-full mt-6 overflow-auto flex  ">
                        <table className="w-full text-center min-w-[900px] ">
                            <thead className="text-[#404D61] text-sm">
                                <tr>
                                    <th>اسم المستخدم</th>
                                    <th>رقم الموبايل</th>
                                    <th>عدد الاصدقاء</th>
                                    <th>عدد المنشورات</th>
                                    <th>عدد النقاط</th>
                                    <th>level</th>
                                    <th> توثيق الحساب</th>
                                    <th> تاريخ الانضمام</th>
                                    <th>تفاصيل</th>
                                </tr>
                                <tr className="h-[10px]" />
                            </thead>
                            <tbody>
                                {data?.data?.map((e) => (
                                    <>
                                        {" "}
                                        <tr
                                            className={`h-[45px] cursor-pointer hover:bg-slate-50 transition-all text-[#6E6B7B] text-sm ${
                                                e.isBlocked
                                                    ? `bg-[#ea545569]`
                                                    : `bg-white`
                                            }`}
                                        >
                                            <td className="w-fit    ">
                                                <div className="flex gap-1 w-fit    items-center  ">
                                                    {e.profileImageUrl ? (
                                                        <img
                                                            src={`${baseURL}${e.profileImageUrl}`}
                                                            alt="صورة المستخدم"
                                                            className="w-[33px] rounded-full"
                                                        />
                                                    ) : (
                                                        <div className="w-[33px] h-[33px] rounded-full bg-main/50" />
                                                    )}
                                                    {e.name}
                                                </div>
                                            </td>
                                            <td>
                                                {" "}
                                                {e.phoneNumber ?? "لا يوجد"}
                                            </td>
                                            <td>{e.countFriends}</td>
                                            <td>{e.countPiont}</td>
                                            <td>{e.countPost}</td>
                                            <td>3</td>
                                            <td>
                                                {e.isVerfied === "false"
                                                    ? "غير موثق"
                                                    : "موثق"}
                                            </td>
                                            <td>{e.dateCreated}</td>
                                            <td
                                                onClick={() =>
                                                    router(`/users/${e.id}`)
                                                }
                                            >
                                                <svg
                                                    className="mx-auto "
                                                    width="3"
                                                    height="13"
                                                    viewBox="0 0 3 13"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <ellipse
                                                        cx="1.40789"
                                                        cy="11.5"
                                                        rx="1.5"
                                                        ry="1.40789"
                                                        transform="rotate(-90 1.40789 11.5)"
                                                        fill="#0189C7"
                                                    />
                                                    <ellipse
                                                        cx="1.40789"
                                                        cy="6.5"
                                                        rx="1.5"
                                                        ry="1.40789"
                                                        transform="rotate(-90 1.40789 6.5)"
                                                        fill="#0189C7"
                                                    />
                                                    <ellipse
                                                        cx="1.40789"
                                                        cy="1.5"
                                                        rx="1.5"
                                                        ry="1.40789"
                                                        transform="rotate(-90 1.40789 1.5)"
                                                        fill="#0189C7"
                                                    />
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr className="h-[10px]" />
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* paggination */}
                    <div className="ltr mx-auto w-fit mt-4">
                        <PaginationCom
                            count={data?.totalPage}
                            page={PageNumber}
                            setPage={setPageNumber}
                            size="small"
                            color="primary"
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default Friends;
