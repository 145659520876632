import { CircularProgress, Container } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import useWebsiteStore from "../store/store";
import { resetPassword } from "../hook/forgetpassword";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = () => {
    const router = useNavigate();
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    const [loading, setloading] = useState(false);
    const token = useWebsiteStore((state) => state.token);
    const [searchParams] = useSearchParams();
    const nameId = searchParams.get("nameId");
    const otp = useWebsiteStore((state) => state.otp);
    const [errors, setErrors] = useState({
        nameId: "",
        password: "",
    });
    const handleForgetPassword = async () => {
        if (!validateForm()) return;
        try {
            setloading(true);
            await resetPassword({ nameId, password, otp }, token);
            toast.success("reset done!!");
            router("/login");
        } catch (error) {
            toast.error(error.message);
        } finally {
            setloading(false);
        }
    };
    if (!otp) {
        router("/login");
        return;
    }
    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!nameId.includes("@gmail.com")) {
            errors.nameId = "الرجاء ادخال الايميل بالشكل الصحيح";
            isValid = false;
        }

        if (password.length < 6) {
            errors.password = "يجب الا تقل كلمة السر عن 6 رموز";
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };
    return (
        <div className=" relative w-full h-[100vh] flex justify-center items-center ">
            <img
                src="/assets/images/bg-layout.svg"
                alt="bg-layout"
                className=" w-[100%] h-[100%] absolute left-0 top-0  object-cover  z-0 "
            />
            <Container>
                <div className="    relative z-10  flex flex-col  gap-10 ">
                    <div className=" flex flex-col gap-[15px] ">
                        {" "}
                        <h1 className="text-[40px]  font-normal  text-blak ">
                            {" "}
                            نسيت كلمة السر؟{" "}
                        </h1>
                        <p className=" font-normal text-[16px]  text-black xsm:w-[439px] w-full">
                            لا داعي للقلق ، فما عليك سوى إرسال بريدك الالكتروني
                            لإعادة تعيين كلمة مرورك
                        </p>
                    </div>
                    {/* login box */}
                    <div className=" flex flex-col gap-[24px]  h-auto xsm:w-[450px] w-[96%] p-[32px]  xsm:min-h-[421px] bg-white rounded-[6px] shadow-custom  justify-center items-center   m-auto">
                        <h1 className=" text-[26px]  font-bold  w-fit mx-auto font-baloo">
                            {" "}
                            Logo{" "}
                        </h1>
                        <div className=" flex flex-col gap-[5px] w-full">
                            <p className=" w-full text-[24px]   font-public-sans font-semibold text-paragraph">
                                نسيت كلمة المرور؟ 🔒
                            </p>
                            <p className=" w-full text-[17px]  font-normal font-public-sans text-paragraph">
                                أدخل بريدك الإلكتروني، وسنرسل لك تعليمات لإعادة
                                تعيين كلمة المرور الخاصة بك
                            </p>
                        </div>
                        <div className=" w-full  flex flex-col gap-[4px] ">
                            <p className=" font-normal text-[13px] font-public-sans w-full  text-paragraph">
                                الايميل
                            </p>
                            <input
                                dir="ltr"
                                type="text"
                                placeholder="john.doe"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className=" 
                                     focus:placeholder:opacity-0 focus:opacity-90  transition-all placeholder:transition-all
                                    outline-none  py-[7px] px-[14px] border   border-1  rounded-[6px]  border-light  w-full "
                            />{" "}
                            {errors.password && (
                                <p className="text-error">{errors.password}</p>
                            )}
                            <input
                                dir="ltr"
                                type="text"
                                placeholder="john.doe"
                                value={confirm}
                                onChange={(e) => setConfirm(e.target.value)}
                                className=" 
                                     focus:placeholder:opacity-0 focus:opacity-90  transition-all placeholder:transition-all
                                    outline-none  py-[7px] px-[14px] border   border-1  rounded-[6px]  border-light  w-full "
                            />
                        </div>
                        <div className=" w-full flex flex-col gap-[16px] ">
                            <button
                                disabled={loading}
                                onClick={() => handleForgetPassword()}
                                style={{
                                    cursor: loading ? "not-allowed" : "pointer",
                                }}
                                className="  bg-main rounded-[6px] text-white w-full text-[15px]    py-[10px] px-[20px]"
                            >
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : (
                                    "اعادة التعيين كلمة السر"
                                )}
                            </button>
                        </div>
                    </div>{" "}
                </div>
            </Container>
        </div>
    );
};

export default ResetPassword;
