import React from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { baseURL } from "../../hook/request";
const Message = ({ message }) => {
    return (
        <div className=" flex flex-col  gap-5  p-5">
            <p className=" text-paragraph-light font-[12px]  w-fit m-auto  ">
                {message?.time}
            </p>
            <div className=" flex md:flex-row  flex-col  justify-between items-start md:gap-1 gap-2 ">
                <div className=" flex flex-row gap-3  justify-start items-start md:max-w-[800px]   ">
                    <img
                        src="/assets/images/reaction.jpg"
                        alt=""
                        className="   rounded cursor-pointer"
                    />
                    <div className=" flex md:flex-row flex-col justify-between gap-3 md:max-w-[800px]  w-full   bg-white  p-[10px] rounded-xl shadow-md">
                        {message?.imageUrls?.[0] && (
                            <div className=" rounded-md overflow-hidden   xsm:w-[260px] md:h-[150px] object-cover  w-[180px] h-[150px]">
                                <img
                                    src={`${baseURL}${message?.imageUrls?.[0]}`}
                                    alt=""
                                    className=" object-cover w-full h-[150px]"
                                />
                            </div>
                        )}
                        <p className=" flex-1 p-2">{message?.text} </p>
                    </div>
                </div>
                <div className=" w-fit cursor-pointer">
                    <RiDeleteBinLine fill="#5E5873" size={24} />
                </div>
            </div>
        </div>
    );
};

export default Message;
