import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import BaseInput from '../base-input';
import { Button, Container } from '@mui/material';   
import  { useAddFounds } from '../../hook/users';
import { toast } from 'react-toastify';
// import CryptoJS from 'crypto-js';

const CodingUpdate = ({ setOpenFrofit, openprofit ,userID,refetch}) => {
 

const [textToEncrypt, setTextToEncrypt] = useState();
const [loading, setLoading] = useState(false);
const [Content,setContent]=useState()
 
const addFoundMutation = useAddFounds({
  onSuccess: () => {
    refetch();
    setLoading(false);
    setOpenFrofit(false);
    setTextToEncrypt(0)
    setTimeout(() => {
      setOpenFrofit(false);
  }, 2000);
    toast.success("Found added successfully!");
  },
  onError: (error) => {
    setLoading(false);
    toast.error("Error adding Found");
  },
  payload: Content,
});

  const handleSubmit=async()=>{
    setLoading(true);
    if(textToEncrypt>1){ try {
      const content = `${userID}${String(textToEncrypt)}${userID}`;
      setContent(content)
    await addFoundMutation.mutateAsync();
  } catch (error) {
    setLoading(false);
  } finally {
    setTextToEncrypt(0)
    setLoading(false);
  }}
  setLoading(false);
  }
  
  return (
    <AnimatePresence>
      {openprofit && (
        <motion.div className="fixed inset-0 flex items-center justify-center z-20 bg-[#00000033] bg-opacity-50">
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="bg-white shadow-lg z-40 relative rounded-[5px] sm:w-[711px] w-[95%]"
          >
            <div className="flex flex-row justify-between items-center bg-[#D3EDB4] rounded-[5px] py-[15px] pr-[35px] pl-[18px]">
              <p className="font-light text-[16px] text-black">اضافه رصيد</p>
              <img
                src="/assets/images/close.svg"
                alt="close"
                onClick={() => {
                  setTextToEncrypt(0)
                  setOpenFrofit(false);
                  setLoading(false);
                }}
                className="cursor-pointer"
              />
            </div>
            <Container>
              <div className="flex sm:flex-row flex-col justify-between p-4">
                <BaseInput
                  value={textToEncrypt}
                  onChange={(e) => setTextToEncrypt(e.target.value)}
                  label={"رقم الرصيد"}
                  type="number"
                  min="0"
                />
              </div>
              <div className="flex flex-row justify-end gap-[10px] p-[3rem]">
                <Button variant="contained" 
                  onClick={handleSubmit}  
                  disabled={loading}>
                  إضافة
                </Button>
                <Button
                  onClick={() => {
                    setTextToEncrypt(0)
                    setOpenFrofit(false);
                    setLoading(false);
                  }}
                  variant="outlined"
                >
                  تراجع
                </Button>
              </div>
            </Container>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CodingUpdate;
