import React from "react";
import { NavLink } from "react-router-dom";

const Icon = ({ children, to = "test" }) => {
  return (
    <NavLink
      to={to}
      className="w-[40px] h-[40px] flex justify-center items-center rounded-md icon transition-all hover:bg-[#f3f3f3]"
    >
      {children}
    </NavLink>
  );
};

export default Icon;
