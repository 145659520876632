import React from "react";

const Replay = ({ e }) => {
    return (
        <div className="flex justify-end w-full ">
            <div className="flex items-start gap-1 justify-end ">
                <div className="bg-[#F1F1F1] text-black/90 rounded-xl min-w-[200px]  max-w-[50%] p-2 text-base">
                    {e.data}
                    <div className="mt-2 text-xs flex w-full justify-between items-center ">
                        <p>{e.time}</p>
                    </div>
                </div>
                {/* <img src="/assets/images/image.png" alt="" /> */}
            </div>
        </div>
    );
};

export default Replay;
