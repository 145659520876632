import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Home from "./pages/home";
import Login from "./pages/login";
import ForgetPassword from "./pages/forger-password";
import FactorAuthentication from "./pages/two-FA";
import Services from "./pages/services";
import Channel from "./pages/channel";
import Settings from "./pages/settings";
import Employee from "./pages/employee";
import Users from "./pages/users";
import Auth from "./auth";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import Notifications from "./pages/notifications";
import Imported from "./pages/imported";
import Posts from "./pages/posts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmployeeDetails from "./components/employee/employee-ditals";
import NotificationDetails from "./components/notifications/notification-details";
import Chat from "./pages/chat";
import UsersDetails from "./components/users/use-details";
import PostDetails from "./components/post/post-details";
import ChannelChat from "./components/channel/channel-caht";
import { QueryClient, QueryClientProvider } from "react-query";
import ResetPassword from "./pages/reset-password";
import Friends from "./components/users/friends";
import PointsRecords from "./components/users/point-records";
import { NotificationChat } from "./pages/notification-chat";
import ChatDet from "./components/users/chat-details";
import Gifts from "./pages/gift";

const routes = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { index: true, element: <Home /> },
            {
                path: "services",
                element: <Services />,
            },
            {
                path: "channel",
                element: <Channel />,
            },
            {
                path: "gifts",
                element:<Gifts/>
            },
            {
                path: "channel/chat/:id",
                element: <ChannelChat />,
            },
            {
                path: "settings",
                element: <Settings />,
            },
            {
                path: "employee",
                element: <Employee />,
            },
            {
                path: "employee/:id",
                element: <EmployeeDetails />,
            },
            {
                path: "users",
                element: <Users />,
            },
            {
                path: "users/:id",
                element: <UsersDetails />,
                children: [
                    {
                        path: "friends",
                        element: <Friends />,
                    },
                    {
                        path: "chat",
                        element: <Chat />,
                        children: [
                            {
                                path: ":convid",
                                index: true,
                                element: <ChatDet />,
                            },
                        ],
                    },
                    {
                        path: "pointrecords",
                        element: <PointsRecords />,
                    },
                ],
            },
            {
                path: "notifications",
                element: <Notifications />,
            },
            {
                path: "notifications/:id",
                element: <NotificationDetails />,
            },
            {
                path: "imported",
                element: <Imported />,
            },
            {
                path: "imported/:id",
                element: <NotificationChat />,
            },
            {
                path: "posts",
                element: <Posts />,
            },
            {
                path: "posts/:id",
                element: <PostDetails />,
            },
        ],
    },
    {
        path: "/login",
        element: <Auth />,
        children: [
            { index: true, element: <Login /> },
            {
                path: "forget-password",
                element: <ForgetPassword />,
            },
            {
                path: "forget-password/Two-Factor-Authentication",
                element: <FactorAuthentication />,
            },
            {
                path: "forget-password/Two-Factor-Authentication/reset-password",
                element: <ResetPassword />,
            },
        ],
    },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
    palette: {
        primary: {
            main: "#0189C7",
        },
    },
});
const queryClient = new QueryClient();

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <ToastContainer />
                <RouterProvider router={routes} />
            </QueryClientProvider>
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals();
