import { Button, Container, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    updateChannel,
    useDeleteChannel,
    useGetChannelById,
} from "../../hook/channel";
import BaseInput from "../base-input";
import BaseTextarea from "../base-textarea";
import useWebsiteStore from "../../store/store";
import InputImage from "../input-image";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
const EditChannel = ({ setOpen, channelId, refetch, open }) => {
    const [imgUrl, setImageUrl] = useState(null);
    const token = useWebsiteStore((state) => state.token);
    const [mounted, setMounted] = useState(true);
    const [loading, setLoading] = useState(false);

    const { data: info, isLoading } = useGetChannelById({
        channelId: channelId,
    });

    const [data, setData] = useState({
        name: "",
        description: "",
    });

    useEffect(() => {
        if (!isLoading && info && mounted) {
            setData({
                description: info?.data?.description,
                name: info?.data?.name,
            });
            setMounted(false);
            setImageUrl(info?.data?.imgUrl);
        }
    }, [info, isLoading, mounted]);

    const { mutate } = useDeleteChannel({
        onSuccess: () => {
            setLoading(false);
            refetch();
            toast.success("Channel deleted successfully!");
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        },
        onError: (error) => {
            setLoading(false);
            toast.error("Error deleting Channel");
        },
        channelId: channelId,
    });
    const handleDelete = async () => {
        setLoading(true);
        await mutate();
    };
    const update = async () => {
        setLoading(true);
        try {
            const payload = {
                imgUrl: imgUrl,
                name: data.name,
                description: data.description,
            };

            await updateChannel(channelId, payload, token);
            refetch();
            toast.success("Channel updated successfully!");
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        } catch (error) {
            setLoading(false);
            toast.error("Error updating Channel");
        }
        setLoading(false);
    };

    return (
        <AnimatePresence>
            {open && (
                <motion.div className="fixed inset-0 flex items-center justify-center z-20 bg-[#00000033] bg-opacity-50">
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="bg-white shadow-lg z-40 relative rounded-[5px]  sm:w-[711px] w-[95%]"
                    >
                        <div className="flex flex-row justify-between items-center bg-[#D3EDB4] rounded-[5px] py-[15px] pr-[35px] pl-[18px] ">
                            <p className="font-light text-[16px] text-black">
                                تعديل قناة
                            </p>
                            <img
                                src="/assets/images/close.svg"
                                alt="close"
                                onClick={() => {
                                    setOpen(false);
                                }}
                                className="cursor-pointer"
                            />
                        </div>
                        {isLoading ? (
                            <div className="w-full flex justify-center items-center h-[50vh]">
                                {" "}
                                <CircularProgress />
                            </div>
                        ) : (
                            <Container>
                                <div className=" flex sm:flex-row flex-col  justify-between p-4  py-[3rem]  ">
                                    <div>
                                        <div className=" flex flex-col gap-2 ">
                                            <BaseInput
                                                label={"اسم القناة"}
                                                value={data.name}
                                                defaultValue={info?.data?.name}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        name: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className=" flex flex-col gap-2 ">
                                            <BaseTextarea
                                                label={"وصف القناة "}
                                                value={data?.description}
                                                defaultValue={
                                                    info?.data?.message
                                                }
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        description:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className=" w-[329px]  h-[247px]   flex flex-col gap-2 ">
                                        {" "}
                                        {info && (
                                            <InputImage
                                                ImageUrl={imgUrl}
                                                setImageUrl={setImageUrl}
                                                type="ChannelImage"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className=" flex flex-row  justify-end gap-[10px] p-[3rem] ">
                                    {" "}
                                    <Button
                                        variant="contained"
                                        disabled={loading}
                                        onClick={update}
                                    >
                                        تعديل
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        variant="outlined"
                                    >
                                        تراجع
                                    </Button>
                                    <Button
                                        color="error"
                                        variant="outlined"
                                        onClick={handleDelete}
                                        disabled={loading}
                                    >
                                        حذف
                                    </Button>
                                </div>
                            </Container>
                        )}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default EditChannel;
