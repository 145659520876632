import React from "react";
import { MdMessage } from "react-icons/md";
import { format } from "date-fns";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

const ImportedCard = ({
    title,
    date,
    body,
    type,
    handleCheck,
    refrenceId,
    id,
    ids,
}) => {
    const router = useNavigate();
    return (
        <div className="text-[#B9B9C3] flex-wrap bg-[#FCFCFC] px-6 ps-4 py-5 text-sm border border-[#ededed]  flex justify-between w-full items-center odd:bg-white">
            <div
                className="flex gap-2 items-start "
                onClick={() => handleCheck(id)}
            >
                {ids.includes(id) ? (
                    <img
                        src="/assets/svg/checkbox.svg"
                        className=" w-4 h-4  mx-auto  cursor-pointer "
                        alt="checked"
                    />
                ) : (
                    <p className="w-4 h-4 border border-input mx-auto rounded-sm cursor-pointer"></p>
                )}
                <div>
                    <p className="text-base">{title}</p>
                    {/* <p className="text-base">{body}</p> */}
                </div>
            </div>
            <div className="flex flex-col justify-center gap-1">
                <p className=" mx-auto text-center">
                    {format(new Date(date ?? ""), "dd-MM-yyyy")}
                </p>
                {type === "post" ? (
                    <Button
                        variant="contained"
                        onClick={() => router(`/posts/${refrenceId}`)}
                    >
                        <div className="flex items-center gap-1 text-xs">
                            عرض المنشور
                            <MdMessage />
                        </div>
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        onClick={() => router(refrenceId)}
                    >
                        <div className="flex items-center gap-1 text-xs">
                            عرض المحادثة
                            <MdMessage />
                        </div>
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ImportedCard;
