import React from "react";
import InputDate from "../inputDate";
import { Icon } from "@mui/material";

const StaticHeader = ({ from, setFrom, to, setTo, pointsSum, levelsSum }) => {
    return (
        <div className=" flex sm:flex-row flex-col gap-3 sm:justify-evenly  justify-center  items-center pb-[2rem]">
            {" "}
            <div className=" flex flex-row justify-between items-center  gap-7">
                <Icon>
                    <svg
                        width="22"
                        height="24"
                        viewBox="0 0 12 15"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="#F8AD24"
                            d="M6.75 9H3.75C3.55109 9 3.36032 9.07902 3.21967 9.21967C3.07902 9.36032 3 9.55109 3 9.75C3 9.94891 3.07902 10.1397 3.21967 10.2803C3.36032 10.421 3.55109 10.5 3.75 10.5H6.75C6.94891 10.5 7.13968 10.421 7.28033 10.2803C7.42098 10.1397 7.5 9.94891 7.5 9.75C7.5 9.55109 7.42098 9.36032 7.28033 9.21967C7.13968 9.07902 6.94891 9 6.75 9ZM9.75 1.5H8.865C8.71027 1.06234 8.42395 0.683249 8.0453 0.414704C7.66665 0.146159 7.21421 0.00130463 6.75 0H5.25C4.78579 0.00130463 4.33335 0.146159 3.9547 0.414704C3.57605 0.683249 3.28974 1.06234 3.135 1.5H2.25C1.65326 1.5 1.08097 1.73705 0.65901 2.15901C0.237053 2.58097 0 3.15326 0 3.75V12.75C0 13.3467 0.237053 13.919 0.65901 14.341C1.08097 14.7629 1.65326 15 2.25 15H9.75C10.3467 15 10.919 14.7629 11.341 14.341C11.7629 13.919 12 13.3467 12 12.75V3.75C12 3.15326 11.7629 2.58097 11.341 2.15901C10.919 1.73705 10.3467 1.5 9.75 1.5ZM4.5 2.25C4.5 2.05109 4.57902 1.86032 4.71967 1.71967C4.86032 1.57902 5.05109 1.5 5.25 1.5H6.75C6.94891 1.5 7.13968 1.57902 7.28033 1.71967C7.42098 1.86032 7.5 2.05109 7.5 2.25V3H4.5V2.25ZM10.5 12.75C10.5 12.9489 10.421 13.1397 10.2803 13.2803C10.1397 13.421 9.94891 13.5 9.75 13.5H2.25C2.05109 13.5 1.86032 13.421 1.71967 13.2803C1.57902 13.1397 1.5 12.9489 1.5 12.75V3.75C1.5 3.55109 1.57902 3.36032 1.71967 3.21967C1.86032 3.07902 2.05109 3 2.25 3H3V3.75C3 3.94891 3.07902 4.13968 3.21967 4.28033C3.36032 4.42098 3.55109 4.5 3.75 4.5H8.25C8.44891 4.5 8.63968 4.42098 8.78033 4.28033C8.92098 4.13968 9 3.94891 9 3.75V3H9.75C9.94891 3 10.1397 3.07902 10.2803 3.21967C10.421 3.36032 10.5 3.55109 10.5 3.75V12.75ZM8.25 6H3.75C3.55109 6 3.36032 6.07902 3.21967 6.21967C3.07902 6.36032 3 6.55109 3 6.75C3 6.94891 3.07902 7.13968 3.21967 7.28033C3.36032 7.42098 3.55109 7.5 3.75 7.5H8.25C8.44891 7.5 8.63968 7.42098 8.78033 7.28033C8.92098 7.13968 9 6.94891 9 6.75C9 6.55109 8.92098 6.36032 8.78033 6.21967C8.63968 6.07902 8.44891 6 8.25 6Z"
                        />
                    </svg>
                </Icon>
                <p className=" texet-[17px] text-paragraph-light font-semibold">
                    {" "}
                    احصائية
                </p>
            </div>
            <div className=" flex flex-col justify-between items-center gap-6  p-3">
                <div className=" flex flex-row  items-center justify-center gap-7">
                    <div className=" flex flex-row justify-center items-center  gap-4">
                        <div className=" w-[14px] h-[14px] rounded-full bg-[#82B941]" />
                        <p className="  font-semibold text-[14px]  text-paragraph-light">
                            level
                        </p>
                    </div>
                    <div className=" flex flex-row justify-between items-center gap-4">
                        <span>{levelsSum}</span>
                        <div
                            dir=" ltr"
                            className=" w-[100px]  h-[14px] rounded-2xl bg-[#E0E0E0] overflow-hidden"
                        >
                            <div
                                dir=" ltr"
                                className=" h-full bg-[#82B941] rounded-2xl"
                                style={{ width: `${(levelsSum / 100) * 100}%` }}
                            >
                                {" "}
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" flex flex-row  items-center justify-center gap-7">
                    <div className=" flex flex-row justify-center items-center  gap-4">
                        <div className=" w-[14px] h-[14px] rounded-full bg-[#FFE500]" />
                        <p className="  font-semibold text-[14px]  text-paragraph-light">
                            النقاط
                        </p>
                    </div>
                    <div className=" flex flex-row justify-between items-center gap-4">
                        <span>{pointsSum}</span>
                        <div
                            dir=" ltr"
                            className=" w-[100px]  h-[14px] rounded-2xl bg-[#E0E0E0] overflow-hidden"
                        >
                            <div
                                dir=" ltr"
                                className=" h-full bg-[#FFE500] rounded-2xl"
                                style={{
                                    width: `${(pointsSum / 100) * 100}% `,
                                }}
                            >
                                {" "}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-4 flex-wrap mt-4 items-center">
                <div className="flex gap-2 items-center ">
                    <span>من</span>
                    <InputDate
                        value={from}
                        max={to}
                        onChange={(e) => {
                            setFrom(e.target.value);
                        }}
                    />
                </div>
                <div className="flex gap-2 items-center ">
                    <span>الى</span>
                    <InputDate
                        value={to}
                        min={from}
                        onChange={(e) => {
                            setTo(e.target.value);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default StaticHeader;
