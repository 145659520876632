import React, { useState } from 'react'
import {motion} from "framer-motion"
import { baseURL } from '../../hook/request'
import EditGift from './edit-gift'

const GiftItem = ({transition,imageUrl,name,price,description,giftId,refetch}) => {

    const [open, setOpen] = useState(false);

  return (
 <>
            <EditGift
                    refetch={refetch}
                    giftId={giftId}
                    open={open}
                    setOpen={setOpen}
            />
    <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: transition / 10 + 0.3 }}
                className=" bg-white rounded-[10px]   h-[287px] "
            >
                <div className="h-[165px]  rounded-[10px] overflow-hidden ">
                    {" "}
                    <img
                        src={`${baseURL}${imageUrl}`}
                        className="w-full object-cover  h-full"
                        alt=""
                    />{" "}
                </div>
                <div className=" p-[20px] flex flex-row gap-[10px]  justify-between ">
                    <p className="  font-semibold texet-[14px]  text-paragraph-dark font-almarai">
                        {name}
                    </p>
                    <p className="  font-semibold texet-[14px]  text-paragraph-dark font-almarai">
                     {" "}${price}
                    </p>
                </div>
                <div>
                    <p className=' font-thin px-[20px]  pb-[10px]'> {description}</p>
                </div>
                <button
                    onClick={() => setOpen(true)}
                    className=" font-almarai  bg-main rounded-[10px] border border-1 whitespace-nowrap text-white w-full h-[41px]   text-[14px]  font-medium    py-[10px] px-[20px]"
                >
                    تعديل
                </button>
            </motion.div></>
  )
}

export default GiftItem