import { Button, CircularProgress, Container } from "@mui/material";
import React, { useState } from "react";
import BaseInput from "../base-input";
import { FaSortDown } from "react-icons/fa";
import { useGetAllUser } from "../../hook/users";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useAddNotification } from "../../hook/notification";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
const AddNotification = ({ open, setOpen, refetch }) => {
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        title: "",
        body: "",
        forAll: false,
        userIds: [],
    });
    const { data, isLoading } = useGetAllUser({
        IsPaginat: false,
        PageNumber: 1,
        PageSize: 10,
        startDate: "",
        endDate: "",
        key: "",
    });

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(typeof value === "string" ? value.split(",") : value);
        const v = event.target.value;
        const newIds = data?.data
            .filter((item) => v.includes(item.name))
            .map((item) => item.id);
        setPayload({ ...payload, userIds: newIds });
    };

    const AddNotification = useAddNotification({
        onSuccess: () => {
            refetch();
            toast.success("Notification added successfully!");
            setPayload({
                title: "",
                body: "",
                forAll: false,
                userIds: [],
            });
        },
        onError: (error) => {
            toast.error("Error adding Notification");
        },
        payload,
    });
    const handleSubmit = async () => {
        setLoading(true);
        try {
            await AddNotification.mutateAsync();
            setOpen(false);
        } catch (error) {
            setLoading(false);
        }
        setLoading(false);
    };
    return (
        <AnimatePresence>
            {open && (
                <motion.div className="fixed inset-0 flex items-center justify-center z-20 bg-[#00000033] bg-opacity-50">
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <motion.div
                            initial={{ scale: 0, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className="bg-white shadow-lg z-40 relative rounded-[5px]  sm:w-[711px] w-[95%]"
                        >
                            <div className="flex flex-row justify-between items-center bg-[#D3EDB4] rounded-[5px] py-[15px] pr-[35px] pl-[18px] ">
                                <div className=" flex flex-row gap-1  justify-center items-center ">
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13.5 6C13.5 4.80653 13.0259 3.66193 12.182 2.81802C11.3381 1.97411 10.1935 1.5 9 1.5C7.80653 1.5 6.66193 1.97411 5.81802 2.81802C4.97411 3.66193 4.5 4.80653 4.5 6C4.5 11.25 2.25 12.75 2.25 12.75H15.75C15.75 12.75 13.5 11.25 13.5 6Z"
                                            stroke="#0189C7"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M10.2976 15.75C10.1658 15.9773 9.97652 16.166 9.74881 16.2971C9.52109 16.4283 9.26292 16.4973 9.00014 16.4973C8.73735 16.4973 8.47918 16.4283 8.25147 16.2971C8.02375 16.166 7.83449 15.9773 7.70264 15.75"
                                            stroke="#0189C7"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    <p className="font-light text-[16px] text-black">
                                        اشعار جديد
                                    </p>
                                </div>

                                <img
                                    src="/assets/images/close.svg"
                                    alt="close"
                                    onClick={() => {
                                        setOpen(false);
                                        setPayload({
                                            title: "",
                                            body: "",
                                            forAll: false,
                                            userIds: [],
                                        });
                                    }}
                                    className="cursor-pointer"
                                />
                            </div>
                            <Container>
                                <div className=" flex   flex-col  justify-center p-4  py-[3rem]  ">
                                    <p className="text-sm font-light gap-4    mb-4 flex">
                                        <span className="text-base flex  font-semibold">
                                            <FaSortDown /> english
                                        </span>
                                        : select input type
                                    </p>
                                    <div className=" flex sm:flex-row flex-col  justify-cneter gap-5       ">
                                        <div className=" felx flex-row justify-between items-center relative ">
                                            <div className="flex w-full justify-between">
                                                <span className="text-[12px] text-paragraph-light">
                                                    ارسال الى
                                                </span>
                                                <div
                                                    className="flex items-center gap-3 absolute cursor-pointer left-0"
                                                    onClick={() =>
                                                        setPayload({
                                                            ...payload,
                                                            forAll: !payload.forAll,
                                                        })
                                                    }
                                                >
                                                    <div className="flex ">
                                                        <div
                                                            className={`w-4 min-w-4 min-h-4 h-4 border border-input  rounded-md  ${
                                                                payload.forAll &&
                                                                "bg-main "
                                                            }`}
                                                        />
                                                        <span className="text-[12px] text-paragraph-light">
                                                            الجميع
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-1  ">
                                                <FormControl
                                                    sx={{
                                                        width: 300,
                                                        height: "40px",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={personName}
                                                        onChange={handleChange}
                                                        renderValue={(
                                                            selected
                                                        ) =>
                                                            selected.join(", ")
                                                        }
                                                        MenuProps={MenuProps}
                                                    >
                                                        {data &&
                                                            data.data?.map(
                                                                (e) => (
                                                                    <MenuItem
                                                                        key={
                                                                            e.id
                                                                        }
                                                                        value={
                                                                            e.name
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            sx={{
                                                                                fontSize:
                                                                                    "12px",
                                                                            }}
                                                                            checked={
                                                                                personName.indexOf(
                                                                                    e.name
                                                                                ) >
                                                                                -1
                                                                            }
                                                                        />
                                                                        <ListItemText
                                                                            primary={
                                                                                e.name
                                                                            }
                                                                        />
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <BaseInput
                                            value={payload.title}
                                            onChange={(e) =>
                                                setPayload({
                                                    ...payload,
                                                    title: e.target.value,
                                                })
                                            }
                                            label={"عنوان رئيسي"}
                                            type={"text"}
                                        />
                                    </div>
                                    <div className=" flex flex-col gap-2  mt-4">
                                        <p className="  font-light text-[12px]   ">
                                            النص
                                        </p>
                                        <textarea
                                            type="text"
                                            value={payload.body}
                                            onChange={(e) =>
                                                setPayload({
                                                    ...payload,
                                                    body: e.target.value,
                                                })
                                            }
                                            className="  focus:placeholder:opacity-0 focus:opacity-90 transition-all placeholder:transition-all outline-none  py-[7px] px-[14px] border   border-1  rounded-[5px]  border-light  min-h-[172px] max-h-[172px]  w-full "
                                        />
                                    </div>
                                </div>
                                <div className=" flex flex-row  justify-end gap-[10px] p-[3rem] ">
                                    <Button
                                        variant="contained"
                                        onClick={() => handleSubmit()}
                                    >
                                        إضافة
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            setOpen(false);
                                            setPayload({
                                                title: "",
                                                body: "",
                                                forAll: false,
                                                userIds: [],
                                            });
                                        }}
                                        className="  border-main rounded-[10px] border border-1 whitespace-nowrap text-main w-fit  text-[14px]  font-medium    py-[10px] px-[20px]"
                                    >
                                        تراجع
                                    </Button>{" "}
                                </div>
                            </Container>
                        </motion.div>
                    )}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default AddNotification;
