import { IoPersonCircle } from "react-icons/io5";
import { baseURL } from "../../hook/request";
import { IoMdDoneAll } from "react-icons/io";
import { MdOutlineDone } from "react-icons/md";

const Contacts = ({ item, onClick }) => {
    return (
        <div
            onClick={onClick}
            className="flex gap-5 py-2 border-b cursor-pointer border-slate-200"
        >
            <div className="w-full flex flex-col h-full justify-between gap-1">
                <div className="w-full flex justify-between items-center">
                    <p className="text-[#707991] text-xs">
                        {item.lastMessage.time}
                    </p>
                    <p className="text-base"> {item?.name}</p>
                </div>
                <div className="w-full flex justify-between  ">
                    {item?.numberMessageNotRead > 0 && (
                        <p className="bg-[#78E378] w-4 h-4 rounded-full text-white flex justify-center items-center text-xs">
                            {item?.numberMessageNotRead}
                        </p>
                    )}
                    <p className="text-base">
                        {item.lastMessage.isRead ? (
                            <IoMdDoneAll className="text-white" id="isRead" />
                        ) : !item.lastMessage.isRecived ? (
                            <MdOutlineDone className="text-slate-100/60" />
                        ) : (
                            <IoMdDoneAll
                                className="text-slate-100/60"
                                id="isNotRead"
                            />
                        )}
                    </p>
                    <p className=" text-[#707991] text-xs  order-1">
                        {item?.lastMessage?.data}
                    </p>
                </div>
            </div>
            {item.profileImageUrl ? (
                <div className="relative">
                    {item.isActive && (
                        <p className="w-[13px] h-[13px] rounded-full bg-[#78E378] absolute top-0 right-0 z-60" />
                    )}
                    <div className="w-11 h-11 object-cover rounded-2xl overflow-hidden">
                        <img
                            src={`${baseURL}${item?.profileImageUrl}`}
                            className="w-full h-full rounded-2xl object-cover"
                            alt="profileImage"
                        />
                    </div>
                </div>
            ) : (
                <IoPersonCircle size={55} />
            )}
        </div>
    );
};

export default Contacts;
