import { useMutation } from "react-query";
import { request } from "./request";

const login = async (data) => {
    const response = await request.post("/api/c_panel/login", data, {});

    return response.data;
};

const useLogin = ({ onSuccess, onError, payload }) => {
    return useMutation(["login", payload], () => login(payload), {
        onSuccess,
        onError,
    });
};

export default useLogin;
