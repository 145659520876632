import React, { useState } from "react";
import Contacts from "../components/chat/contacts";
import { useGetAllUsersConversation } from "../hook/users";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import PaginationCom from "../components/pagination";
import { CircularProgress } from "@mui/material";
import useWebsiteStore from "../store/store";
import { useDebounce } from "use-debounce";

const Chat = () => {
    const { id } = useParams();
    const location = useLocation();
    const open = useWebsiteStore((state) => state.openChat);
    const setOpen = useWebsiteStore((state) => state.setOpenChat);
    const router = useNavigate();
    const [withAdmin, setwithAdmin] = useState(false);
    const [PageNumber, setPageNumber] = useState(1);
    const [search, setSearch] = useState("");
    const [key] = useDebounce(search, 500);
    const { data, isLoading } = useGetAllUsersConversation(id, {
        IsPaginat: true,
        PageNumber: 1,
        PageSize: 10,
        withAdmin: withAdmin,
        key: key,
    });

    return (
        <>
            {open && (
                <div
                    onClick={() => setOpen(false)}
                    className="fixed sm:hidden inset-0 flex  items-center justify-center z-20 bg-[#00000033] bg-opacity-50 "
                >
                    <div className="flex flex-col h-[80%] w-[80%] bg-white overflow-auto rounded-md ">
                        <div>
                            <div className="flex h-fit gap-10  pt-12 pb-4 border-b border-slate-200 px-6">
                                <div className="flex whitespace-nowrap gap-5 text-sm">
                                    <p
                                        className={
                                            withAdmin
                                                ? "font-bold cursor-pointer"
                                                : "font-normal  cursor-pointer"
                                        }
                                        onClick={() => setwithAdmin(!withAdmin)}
                                    >
                                        مع أدمن{" "}
                                    </p>
                                    <p
                                        className={
                                            !withAdmin
                                                ? "font-bold cursor-pointer"
                                                : "font-normal  cursor-pointer"
                                        }
                                        onClick={() => setwithAdmin(!withAdmin)}
                                    >
                                        بدون أدمن{" "}
                                    </p>
                                </div>
                                <p className="flex gap-3 items-center font-bold text-base   ">
                                    <span className="w-6 font-semibold rounded-full flex justify-center items-center text-xs h-6 bg-slate-200 ">
                                        {data?.data?.length}
                                    </span>
                                    المحادثات
                                </p>
                            </div>
                            <div className="w-full px-6">
                                <input
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    name=""
                                    className="bg-[#F3F3F3] placeholder:text w-full mx-auto mt-4  px-5 py-3 outline-none focus:placeholder:opacity-0 placeholder:transition-all rounded-md "
                                    placeholder="بحث عن رسالة "
                                    id=""
                                />
                            </div>
                        </div>
                        <div className="h-full overflow-auto mt-4 px-4 items-left">
                            {isLoading ? (
                                <div className="w-full flex justify-center items-center h-[50vh]">
                                    {" "}
                                    <CircularProgress />
                                </div>
                            ) : (
                                data?.data?.map((item) => (
                                    <Contacts
                                        onClick={() => {
                                            router(
                                                `/users/${id}/chat/${item.id}`
                                            );
                                        }}
                                        key={item.id}
                                        item={item}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className="w-full flex bg-white h-full max-sm:flex-col min-h-[80vh] ">
                {location.pathname.endsWith("/chat") ? (
                    <div className="bg-red-500 h-full w-full" />
                ) : (
                    <Outlet />
                )}
                <div className="flex flex-col max-sm:hidden  ">
                    <div>
                        <div className="flex h-fit gap-10  pt-12 pb-4 border-b border-slate-200 px-6 justify-left ">
                            <div className="flex whitespace-nowrap gap-5 text-sm">
                                <p
                                    className={
                                        withAdmin
                                            ? "font-bold cursor-pointer"
                                            : "font-normal cursor-pointer"
                                    }
                                    onClick={() => setwithAdmin(!withAdmin)}
                                >
                                    مع أدمن{" "}
                                </p>
                                <p
                                    className={
                                        !withAdmin
                                            ? "font-bold cursor-pointer"
                                            : "font-normal  cursor-pointer"
                                    }
                                    onClick={() => setwithAdmin(!withAdmin)}
                                >
                                    بدون أدمن{" "}
                                </p>
                            </div>
                            <p className="flex gap-3 items-center font-bold text-base   ">
                                <span className="w-6 font-semibold rounded-full flex justify-center items-center text-xs h-6 bg-slate-200 ">
                                    {data?.data?.length}
                                </span>
                                المحادثات
                            </p>
                        </div>
                        <div className="w-full px-6">
                            <input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                type="text"
                                name=""
                                className="bg-[#F3F3F3] placeholder:text w-full mx-auto mt-4  px-5 py-3 outline-none focus:placeholder:opacity-0 placeholder:transition-all rounded-md "
                                placeholder="بحث عن رسالة "
                                id=""
                            />
                        </div>
                    </div>
                    <div className="h-full overflow-auto mt-4 px-4">
                        {isLoading ? (
                            <div className="w-full flex justify-center items-center h-[50vh]">
                                {" "}
                                <CircularProgress />
                            </div>
                        ) : (
                            data?.data?.map((item) => (
                                // <Link
                                //   to={`/users/${id}/chat/${item.id}`}
                                //   className="text-paragraph-light"
                                // >
                                <Contacts
                                    onClick={() => {
                                        // setOpen(false);
                                        router(`/users/${id}/chat/${item.id}`);
                                    }}
                                    key={item.id}
                                    item={item}
                                />
                                // </Link>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <div className="ltr mx-auto w-fit mt-4">
                <PaginationCom
                    count={data?.totalPage}
                    page={PageNumber}
                    setPage={setPageNumber}
                    size="small"
                    color="primary"
                />
            </div>
        </>
    );
};

export default Chat;
