import { request } from "./request";

const forgetPassword = async (data, token) => {
  const response = await request.post("/api/c_panel/forget_password", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const resetPassword = async (data, token) => {
  const response = await request.post("/api/user/reset_password", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const verifyOtp = async (data, token) => {
  const response = await request.post("/api/c_panel/verify_otp", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export { forgetPassword, resetPassword, verifyOtp };
