import { CircularProgress, Container } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import useWebsiteStore from "../store/store";
import { forgetPassword } from "../hook/forgetpassword";

import { toast } from "react-toastify";
const ForgetPassword = () => {
    const router = useNavigate();
    const [nameId, setNameId] = useState("");
    const [loading, setloading] = useState(false);
    const token = useWebsiteStore((state) => state.token);
    const [errors, setErrors] = useState({
        nameId: "",
        password: "",
    });

    const handleForgetPassword = async () => {
        if (!validateForm()) return;
        try {
            setloading(true);
            await forgetPassword({ nameId }, token);
            router(`Two-Factor-Authentication?nameId=${nameId}`);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setloading(false);
        }
    };
    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!nameId.includes("@gmail.com")) {
            errors.nameId = "الرجاء ادخال الايميل بالشكل الصحيح";
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };
    const handleChange = (field) => (e) => {
        setNameId(e.target.value);
        setErrors({ ...errors, [field]: "" });
    };

    return (
        <div className=" relative w-full h-[100vh] flex justify-center items-center ">
            <img
                src="/assets/images/bg-layout.svg"
                alt="bg-layout"
                className=" w-[100%] h-[100%] absolute left-0 top-0  object-cover  z-0 "
            />
            <Container>
                <div className="    relative z-10  flex flex-col  gap-10 ">
                    <div className=" flex flex-col gap-[15px] ">
                        {" "}
                        <h1 className="text-[40px]  font-normal  text-blak ">
                            {" "}
                            نسيت كلمة السر؟{" "}
                        </h1>
                        <p className=" font-normal text-[16px]  text-black xsm:w-[439px] w-full">
                            لا داعي للقلق ، فما عليك سوى إرسال بريدك الالكتروني
                            لإعادة تعيين كلمة مرورك
                        </p>
                    </div>
                    {/* login box */}
                    <div className=" flex flex-col gap-[24px]  h-auto xsm:w-[450px] w-[96%] p-[32px]  xsm:min-h-[421px] bg-white rounded-[6px] shadow-custom  justify-center items-center   m-auto">
                        <h1 className=" text-[26px]  font-bold  w-fit mx-auto font-baloo">
                            {" "}
                            Logo{" "}
                        </h1>
                        <div className=" flex flex-col gap-[5px] w-full">
                            <p className=" w-full text-[24px]   font-public-sans font-semibold text-paragraph">
                                نسيت كلمة المرور؟ 🔒
                            </p>
                            <p className=" w-full text-[17px]  font-normal font-public-sans text-paragraph">
                                أدخل بريدك الإلكتروني، وسنرسل لك تعليمات لإعادة
                                تعيين كلمة المرور الخاصة بك
                            </p>
                        </div>
                        <div className=" w-full  flex flex-col gap-[4px] ">
                            <p className=" font-normal text-[13px] font-public-sans w-full  text-paragraph">
                                الايميل
                            </p>
                            <input
                                dir="ltr"
                                type="text"
                                placeholder="john.doe"
                                value={nameId}
                                onChange={handleChange("nameId")}
                                className=" 
                                     focus:placeholder:opacity-0 focus:opacity-90  transition-all placeholder:transition-all
                                    outline-none  py-[7px] px-[14px] border   border-1  rounded-[6px]  border-light  w-full "
                            />
                            {errors.nameId && (
                                <p className="text-error">{errors.nameId}</p>
                            )}
                        </div>
                        <div className=" w-full flex flex-col gap-[16px] ">
                            <button
                                disabled={loading}
                                onClick={() => handleForgetPassword()}
                                style={{
                                    cursor: loading ? "not-allowed" : "pointer",
                                }}
                                className="  bg-main rounded-[6px] text-white w-full text-[15px]    py-[10px] px-[20px]"
                            >
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : (
                                    " ارسل رابط اعادة التعيين"
                                )}
                            </button>
                            <div
                                onClick={() => router("/login")}
                                className=" font-normal text-[15px]  text-main  w-fit mx-auto  cursor-pointer flex flex-row  items-center  gap-[5px]  justify-center text-center"
                            >
                                <p> العودة لواجهة تسجيل الدخول </p>
                                <img
                                    src="/assets/images/chevron-left.svg"
                                    alt=""
                                    className=" w-[20px] h-[20px] "
                                />
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </Container>
        </div>
    );
};

export default ForgetPassword;
