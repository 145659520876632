import { Outlet } from "react-router";
import "./App.css";
import Sidebar from "./components/sidebar/sidebar";
import Header from "./components/header/header";
import TestAuth from "./test-auth";

function App() {
  return (
    <div className="App flex w-full h-screen bg-mainBackground ">
      <Sidebar />
      <div className="flex h-full flex-col w-full mainWidth ">
        <Header />
        <div className="h-full  px-4 sm:px-6 overflow-auto pb-10 pt-0">
          <TestAuth>
            <Outlet />
          </TestAuth>
        </div>
      </div>
    </div>
  );
}

export default App;
