import React from "react";
import { useNavigate } from "react-router";
import { EnumTypeNotifications } from "../../hook/notification";
import { format } from "date-fns";
const CardNotification = ({
    ids,
    id,
    handleCheck,
    title,
    date,
    body,
    typeSend,
}) => {
    const router = useNavigate();
    return (
        <div
            onClick={() => router(`/notifications/${id}`)}
            className="text-[#B9B9C3]  cursor-pointer bg-[#FCFCFC] px-6 ps-4 py-5 text-sm border border-[#ededed]  odd:bg-white"
        >
            <div className="w-full flex text-sm justify-between items-center ps-10 flex-wrap gap-1">
                <p className="text-[#5E5873]"> {title}</p>
                <p className="text-[#5E587380] ">
                    المرسل اليه:{" "}
                    {typeSend === EnumTypeNotifications.All
                        ? "جميع المستخدمين"
                        : "ss"}
                </p>
                <p className="text-xs">
                    {" "}
                    {format(new Date(date ?? ""), "dd-MM-yyyy")}
                </p>
            </div>
            <div className="flex items-center gap-4 mt-6">
                <span onClick={() => handleCheck(id)}>
                    {ids.includes(id) ? (
                        <img
                            src="/assets/svg/checkbox.svg"
                            className=" w-4 h-4  mx-auto  cursor-pointer "
                            alt="checked"
                        />
                    ) : (
                        <p className="w-4 h-4 border border-input mx-auto rounded-sm cursor-pointer"></p>
                    )}{" "}
                </span>
                <p>{body}</p>
            </div>
        </div>
    );
};

export default CardNotification;
