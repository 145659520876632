import { Button, Checkbox, Container, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import BaseInput from "../base-input";
import { updateCategory, useGetCategoryById } from "../../hook/settings";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

import useWebsiteStore from "../../store/store";
import InputImage from "../input-image";
import { AnimatePresence, motion } from "framer-motion";

const EditCategory = ({ setOpen, id, refetchdata, openEdit }) => {
    const [ImageUrl, setImageUrl] = useState(null);
    const [mounted, setMounted] = useState(true);
    const { data: info, isLoading } = useGetCategoryById({ id: id });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        name: info?.data?.name,
        onlyVideo: info?.data?.onlyVideo,
        displayForUser: info?.data?.displayForUser,
    });
    useEffect(() => {
        if (!isLoading && info && mounted) {
            setData({
                name: info?.data?.name,
                onlyVideo: info?.data?.onlyVideo,
                displayForUser: info?.data?.displayForUser,
            });
            setImageUrl(info?.data?.urlImage);
            setMounted(false);
        }
    }, [info, isLoading, mounted]);

    useEffect(() => {
        setData({ ...data, urlImage: ImageUrl });
    }, [ImageUrl]);

    const token = useWebsiteStore((state) => state.token);
    const update = async () => {
        setLoading(true);
        try {
            const payload = {
                urlImage: data.urlImage,
                name: data.name,
                onlyVideo: data.onlyVideo,
                displayForUser: data.displayForUser,
            };
            await updateCategory(payload, token, id);
            setLoading(false);
            refetchdata(); 
            toast.success("Category updated successfully!");
            setTimeout(() => {
                setOpen(false);
                setImageUrl(null)
                setData({ name: "", urlImage: "", onlyVideo: "" ,displayForUser:""});
            }, 3000);
        } catch (error) {
            setLoading(false);
            toast.error("Error updating Category");
        }
    };
    return (
        <AnimatePresence>
            {openEdit && (
                <motion.div className="fixed inset-0 flex items-center justify-center z-20 bg-[#00000033] bg-opacity-50">
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="bg-white shadow-lg z-40 relative rounded-[5px]  sm:w-[711px] w-[95%]"
                    >
                        <div className="flex flex-row justify-between items-center bg-[#D3EDB4] rounded-[5px] py-[15px] pr-[35px] pl-[18px] ">
                            <p className="font-light text-[16px] text-black">
                                تعديل تصنيف
                            </p>
                            <img
                                src="/assets/images/close.svg"
                                alt="close"
                                onClick={() => {
                                    setOpen(false);
                                    setImageUrl(null)
                                    setData({ name: "", urlImage: "", onlyVideo: "" ,displayForUser:""});
                                }}
                                className="cursor-pointer"
                            />
                        </div>
                        {isLoading ? (
                            <div className="w-full flex justify-center items-center h-[50vh]">
                                {" "}
                                <CircularProgress />
                            </div>
                        ) : (
                            <Container>
                                <div className=" flex sm:flex-col flex-col  justify-between p-4  gap-[3rem] py-[3rem]  ">
                                    <div className="flex sm:flex-row flex-col items-center gap-8">
                                          <BaseInput
                                                label={"اسم التصنيف"}
                                                type="text"
                                                value={data.name}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        name: e.target.value,
                                                    })
                                                }
                                            /> 
                                       <div className=" flex flex-row gap-3 justify-between">
                                       <div className=" w-full">
                                            <FormControlLabel
                                                dir="rtl"
                                                control={
                                                    <Checkbox
                                                        checked={data.onlyVideo}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                onlyVideo:
                                                                    e.target
                                                                        .checked,
                                                            })
                                                        }
                                                    />
                                                }
                                                label="فقط فيديوهات"
                                                sx={{
                                                    "& .MuiFormControlLabel-label":
                                                        {
                                                            fontSize: "13px",
                                                            fontWeight:
                                                                "normal",
                                                            color: "#4B465C",
                                                            width: "auto",
                                                                  whiteSpace:'nowrap'

                                                            
                                                        },
                                                }}
                                            />
                                        </div>
                                        <div className=" w-full">
                                            <FormControlLabel
                                                dir="rtl"
                                                control={
                                                    <Checkbox
                                                        checked={data.displayForUser}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                displayForUser:
                                                                    e.target
                                                                        .checked,
                                                            })
                                                        }
                                                    />
                                                }
                                                label=" اظهار للمستخدمين"
                                                sx={{
                                                    "& .MuiFormControlLabel-label":
                                                        {
                                                            fontSize: "13px",
                                                            fontWeight:
                                                                "normal",
                                                            color: "#4B465C",
                                                            width: "auto",
                                                                  whiteSpace:'nowrap'
                                                        },
                                                }}
                                            />
                                        </div>
                                       </div>
                                    </div>
                                    <div className=" w-[329px]  h-[247px]   flex flex-col gap-2 ">
                                        {info && (
                                            <InputImage
                                                ImageUrl={ImageUrl}
                                                setImageUrl={setImageUrl}
                                                type="CategoryImage"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className=" flex flex-row  justify-end gap-[10px] p-[2rem] ">
                                    {" "}
                                    <Button
                                        variant="contained"
                                        onClick={update}
                                        disabled={loading}
                                    >
                                        تعديل
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        تراجع
                                    </Button>
                                </div>
                            </Container>
                        )}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default EditCategory;
