import React from "react";
import ChannelMenu from "./channel-menu";
import ChannelInterface from "./channel-interface";
const ChannelChat = () => {
    return (
        <>
            {/* {isLoading ? (
                <div className="w-full flex justify-center items-center h-[50vh]">
                    {" "}
                    <CircularProgress />
                </div>
            ) : ( */}
            <div className=" h-full flex w-full">
                <ChannelInterface />
                <ChannelMenu />
            </div>
            {/* )} */}
        </>
    );
};

export default ChannelChat;
