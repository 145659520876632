import React from "react";

const BaseInput = ({ label, ...props }) => {
    return (
        <div className=" flex flex-col gap-2 w-full ">
            <p className="  font-light text-[12px]   "> {label}</p>
            <input
                {...props}
                dir="ltr"
                className=" placeholder:text-xs   focus:placeholder:opacity-0 focus:opacity-90 transition-all placeholder:transition-all outline-none  py-[7px] px-[14px] border   border-1  rounded-[5px]  border-light   w-full "
            />
        </div>
    );
};

export default BaseInput;
